import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import CpDebouncedTextInput from "components/common/CpDebouncedTextInput";
import CpSelect from "components/common/CpSelect";
import CpAdminOrganizationsCsv from "components/organization/admin/CpOrganizationsCsv";
import PrCheckbox from "components/primitives/PrCheckbox";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { css, styles } from "services/Theme";
const localTranslations = defineMessages({
  allExperiences: {
    id: "rvxsNr",
    defaultMessage: "All Experiences"
  },
  allRegions: {
    id: "Txxj6A",
    defaultMessage: "All Regions"
  },
  searchOrganizations: {
    id: "iOHKsJ",
    defaultMessage: "Search organizations"
  },
  showInactive: {
    id: "UDec1R",
    defaultMessage: "Show Inactive"
  }
});
const localStyles = {
  checkbox: css`
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  csvButtonContainerDesktop: css`
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  inactiveContainer: css`
    align-items: center;
    flex-direction: row;
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    padding-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  searchAndCsv: css`
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  `,
  searchBox: css`
    background-color: ${({
    theme
  }) => theme.formColors.fieldBackground};
    border-color: ${({
    theme
  }) => theme.formColors.fieldBorderInactive};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-width: 1px;
    flex: 1;
    flex-direction: row;
  `,
  searchBoxText: css`
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  searchIcon: css`
    align-items: center;
    justify-content: center;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  selectContainer: css`
    flex-direction: row;
    justify-content: space-between;
    z-index: ${({
    theme
  }) => theme.layers.thirdLayer};
  `,
  selectContainerDesktop: css`
    align-items: center;
    flex-direction: row;
  `,
  selectFirst: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  selectSpacingDesktop: css`
    padding-right: ${({
    theme
  }) => theme.spacing.double}px;
  `
};

/**
 * This component renders the filters for the organization search page
 */
const CpOrganizationsFilters = ({
  inactive,
  onChangeInactive,
  onChangeSearchText,
  searchText
}) => {
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    textColors
  } = useAppTheme();
  const renderShowInactiveCheckbox = useMemo(() => {
    return <_StyledPrCheckbox label={localTranslations.showInactive} onChangeOption={onChangeInactive} value={inactive} $_css={localStyles.checkbox} />;
  }, [inactive, onChangeInactive]);
  return <>
      <_StyledView $_css2={localStyles.searchAndCsv}>
        <_StyledView2 $_css3={localStyles.searchBox}>
          <_StyledView3 $_css4={localStyles.searchIcon}>
            <PrIcon fill={textColors.default} icon={IconName.search} />
          </_StyledView3>
          <_StyledCpDebouncedTextInput onChangeText={onChangeSearchText} placeholder={localTranslations.searchOrganizations} value={searchText} $_css5={localStyles.searchBoxText} />
        </_StyledView2>

        {isWideScreen ? <_StyledView4 $_css6={localStyles.csvButtonContainerDesktop}>
            <CpAdminOrganizationsCsv name={searchText} status={inactive ? "" : "active"} />
          </_StyledView4> : undefined}
      </_StyledView>
      <_StyledView5 $_css7={[!isWideScreen ? localStyles.selectContainer : localStyles.selectContainerDesktop]}>
        <_StyledView6 $_css8={[!isWideScreen ? styles.flexSingle : localStyles.selectSpacingDesktop, !isWideScreen ? localStyles.selectFirst : undefined]}>
          <CpSelect onChange={() => null} selectOptions={[{
          name: formatMessage(localTranslations.allRegions),
          value: "all"
        }]} value="all" />
        </_StyledView6>
        <_StyledView7 $_css9={[!isWideScreen ? styles.flexSingle : localStyles.selectSpacingDesktop]}>
          <CpSelect onChange={() => null} selectOptions={[{
          name: formatMessage(localTranslations.allExperiences),
          value: "all"
        }]} value="all" />
        </_StyledView7>
        {isWideScreen ? renderShowInactiveCheckbox : undefined}
      </_StyledView5>
      {!isWideScreen ? <_StyledView8 $_css10={localStyles.inactiveContainer}>{renderShowInactiveCheckbox}</_StyledView8> : undefined}
    </>;
};

export default CpOrganizationsFilters;

var _StyledPrCheckbox = _styled(PrCheckbox).withConfig({
  displayName: "CpOrganizationsFilters___StyledPrCheckbox",
  componentId: "sc-1ul997z-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationsFilters___StyledView",
  componentId: "sc-1ul997z-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpOrganizationsFilters___StyledView2",
  componentId: "sc-1ul997z-2"
})(["", ""], p => p.$_css3);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpOrganizationsFilters___StyledView3",
  componentId: "sc-1ul997z-3"
})(["", ""], p => p.$_css4);

var _StyledCpDebouncedTextInput = _styled(CpDebouncedTextInput).withConfig({
  displayName: "CpOrganizationsFilters___StyledCpDebouncedTextInput",
  componentId: "sc-1ul997z-4"
})(["", ""], p => p.$_css5);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpOrganizationsFilters___StyledView4",
  componentId: "sc-1ul997z-5"
})(["", ""], p => p.$_css6);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpOrganizationsFilters___StyledView5",
  componentId: "sc-1ul997z-6"
})(["", ""], p => p.$_css7);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpOrganizationsFilters___StyledView6",
  componentId: "sc-1ul997z-7"
})(["", ""], p => p.$_css8);

var _StyledView7 = _styled(View).withConfig({
  displayName: "CpOrganizationsFilters___StyledView7",
  componentId: "sc-1ul997z-8"
})(["", ""], p => p.$_css9);

var _StyledView8 = _styled(View).withConfig({
  displayName: "CpOrganizationsFilters___StyledView8",
  componentId: "sc-1ul997z-9"
})(["", ""], p => p.$_css10);