import React, { useCallback, useState } from "react";
import CpDarkMode from "components/common/CpDarkMode";
import CpHeaderButton from "components/common/CpHeaderButton";
import CpPopup from "components/common/CpPopup";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import { useIntl } from "react-intl";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * Dark Mode switch for our dark mode
 */

const CpDarkModeButton = ({
  type = ActionButtonUserType.AccountMenu
}) => {
  const {
    formatMessage
  } = useIntl(); // Theme menu state and callbacks

  const [showThemePopup, setShowThemePopup] = useState(false);
  const handleHideThemePopup = useCallback(() => setShowThemePopup(false), [setShowThemePopup]);
  const handleShowThemePopup = useCallback(() => setShowThemePopup(true), [setShowThemePopup]); // Dark Mode Popup Layout

  const [modePopupLayout, setModePopupLayout] = useState({
    width: 0,
    x: 0,
    y: 0
  });
  const handleModeLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setModePopupLayout({
      width: 200,
      x: layout.left + layout.width - 200,
      y: layout.top + layout.height
    });
  }, [setModePopupLayout]);
  return <>
      {
      /* Theme picker */
    }
      <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navHeaderColorModeButton)} icon={IconName.moon} label={translations.labels.colorModeButton} onLayout={handleModeLayout} onPress={handleShowThemePopup} testID={type === ActionButtonUserType.AccountMenu ? "CpDarkModeButton-accountMenu" : "CpDarkModeButton-header"} type={type} />

      <CpPopup layout={modePopupLayout} onBackgroundPress={handleHideThemePopup} popupStyle={[styles.popupBorder, styles.navHeaderStyles.darkModePopup]} visible={showThemePopup}>
        <CpDarkMode />
      </CpPopup>
    </>;
};

export default CpDarkModeButton;