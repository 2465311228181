import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import useScreenLayout from "hooks/useScreenLayout";
import { styles } from "services/Theme";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import translations from "translations";
import PrText from "components/primitives/PrText";

/**
 * Renders the details of an organizations configuration regarding colours and styling of the application.
 */
const CpOrganizationConfigStyles = ({
  organization
}) => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const appConfig = organization?.appConfig;
  return <_StyledView $_css={isMediumOrSmallerScreen ? styles.paddingDouble : undefined}>
      <CpReadonlyInput label={translations.attributes.appStyles.defaultFont} value={appConfig?.defaultFontName} fallbackValue={translations.attributes.appStyles.systemDefault} />
      <CpReadonlyInput label={translations.attributes.appStyles.headingFont} value={appConfig?.headingFontName} fallbackValue={translations.attributes.appStyles.systemDefault} />
      <CpReadonlyInput label={translations.attributes.appStyles.buttonFont} value={appConfig?.buttonFontName} fallbackValue={translations.attributes.appStyles.systemDefault} />

      <_StyledPrText children={translations.attributes.appStyles.colorThemes} $_css2={styles.formStyles.readonlyLabel} />
      {appConfig?.appColors.map(theme => <_StyledPrText2 children={translations.enums.appColorThemes[theme.colorScheme]} $_css3={styles.formStyles.readonlyText} />)}
      <_StyledPrText3 children="Here we would ideally show the colour palette used by the organization with some fancy layout and swatches for each colour, plus sample components or even an option to see the app using this configuration." $_css4={styles.marginTopDouble} />
    </_StyledView>;
};

export default CpOrganizationConfigStyles;

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationConfigStyles___StyledView",
  componentId: "sc-yp7uk7-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigStyles___StyledPrText",
  componentId: "sc-yp7uk7-1"
})(["", ""], p => p.$_css2);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigStyles___StyledPrText2",
  componentId: "sc-yp7uk7-2"
})(["", ""], p => p.$_css3);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigStyles___StyledPrText3",
  componentId: "sc-yp7uk7-3"
})(["", ""], p => p.$_css4);