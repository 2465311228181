import _styled from "styled-components";
import React, { useMemo } from "react";
import { View } from "react-native";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import { AppTool } from "services/Constants";
import { styles } from "services/Theme";
import translations from "translations";
import useScreenLayout from "hooks/useScreenLayout";

/**
 * Renders the details of an organizations configuration regarding tools available in the application.
 */
const CpOrganizationConfigTools = ({
  organization
}) => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const appConfig = organization?.appConfig; // Capture tool status based on available enums, and whether the tool is available in the config (no tool in config === disabled)

  const toolStatuses = useMemo(() => {
    return Object.keys(AppTool).map(tool => {
      const currentAppTool = appConfig?.appTools?.find(configTool => configTool.name === tool);
      return <CpReadonlyInput key={tool} label={translations.enums.appTools[tool]} value={currentAppTool?.status === "active" ? translations.labels.enabled : translations.labels.disabled} />;
    });
  }, [appConfig?.appTools]);
  return <_StyledView $_css={isMediumOrSmallerScreen ? styles.paddingDouble : undefined}>{toolStatuses}</_StyledView>;
};

export default CpOrganizationConfigTools;

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationConfigTools___StyledView",
  componentId: "sc-e2szsz-0"
})(["", ""], p => p.$_css);