import _styled from "styled-components";
import React, { useMemo } from "react";
import CpOfflineIndicator from "components/common/CpOfflineIndicator";
import CtAppInitializers from "components/containers/CtAppInitializers";
import CtErrorBoundary from "components/containers/CtErrorBoundary";
import CtNavigation from "components/containers/CtNavigation";
import NvAdminRoot from "navigators/NvAdminRoot";
import Config from "services/Config";
import Log from "services/Log";
import { styles } from "services/Theme";
Log.info("Starting admin app", Config);
/**
 * This container component wraps the app with the providers it needs to operate in a web environment.
 */

const AdminApp = () => {
  const appPrefixes = useMemo(() => {
    return ["https://admin.us.imdhealth.app", "imdhealth://", "http://localhost:9002"];
  }, []);
  return <CtAppInitializers>
      <_StyledCtErrorBoundary $_css={styles.paddingDouble}>
        <CtNavigation prefixes={appPrefixes}>
          <CpOfflineIndicator />
          <NvAdminRoot />
        </CtNavigation>
      </_StyledCtErrorBoundary>
    </CtAppInitializers>;
};

export default AdminApp;

var _StyledCtErrorBoundary = _styled(CtErrorBoundary).withConfig({
  displayName: "AdminAppweb___StyledCtErrorBoundary",
  componentId: "sc-13rg0qi-0"
})(["", ""], p => p.$_css);