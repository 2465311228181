import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Platform, Pressable, View } from "react-native";
import CpAccountMenu from "components/app/CpAccountMenu";
import CpDarkModeButton from "components/app/CpDarkModeButton";
import { NavTitles } from "components/app/header.types";
import CpDesktopHeaderButton from "components/app/header/CpDesktopHeaderButton";
import CpImdLogo from "components/common/CpImdLogo";
import PrActionButton from "components/primitives/PrActionButton";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import useActiveNavigationKey from "hooks/useActiveNavigationKey";
import useAppRegion from "hooks/useAppRegion";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { css, styles } from "services/Theme";
import { logoSize } from "services/Theme/common";
import translations from "translations";
const defaultMenuActions = ["CHANGE_CLINIC", "HELP", "SIGN_OUT", "MODE"];
const localStyles = {
  navMenu: css`
    flex-direction: row;
    padding-left: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `
};

/**
 * The header at the top of each screen for the admin app.
 */
const CpAdminNavHeader = ({
  navigation,
  route
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    name,
    textColors
  } = useAppTheme();
  const {
    currentRegionTranslation
  } = useAppRegion();
  const handleLogoPress = useCallback(() => {
    navigation.navigate(Routes.home);
  }, [navigation]); // Admin Menu State

  const [dropdownOpen, setDropdownOpen] = useState({
    [NavTitles.Content]: false
  });
  const handleShowDropdown = useCallback(key => {
    setDropdownOpen({ ...dropdownOpen,
      [key]: true
    });
  }, [dropdownOpen, setDropdownOpen]);
  const handleHideDropdown = useCallback(key => {
    setDropdownOpen({ ...dropdownOpen,
      [key]: false
    });
  }, [dropdownOpen, setDropdownOpen]); // Admin Menu Route Callbacks

  const {
    navigate
  } = useNavigation();
  const handleOrganizationsPress = useCallback(() => {
    handleHideDropdown(NavTitles.Content);
    navigate(Routes.organizations);
  }, [handleHideDropdown, navigate]);
  const menuOptions = [{
    icon: IconName.iconContent,
    navGroups: [{
      options: [{
        name: formatMessage(translations.labels.organizations),
        onPress: handleOrganizationsPress
      }]
    }],
    title: NavTitles.Content
  }];
  const currentRegion = formatMessage(currentRegionTranslation);
  const navKey = useActiveNavigationKey(); // This if statement prevents multiple copies of headers from being rendered in float mode when you navigate between pages on web

  if (Platform.OS === "web" && navKey !== route.key) {
    return null;
  } else {
    return <_StyledView $_css={styles.navHeaderStyles.container}>
        <_StyledView2 $_css2={styles.navHeaderStyles.row1Container}>
          <_StyledView3 $_css3={styles.navHeaderStyles.logoAndMenu}>
            <_StyledPressable accessibilityHint={formatMessage(translations.accessibility.hint.navHeaderLogoButton)} accessibilityLabel={formatMessage(translations.accessibility.label.imdLogo, {
            region: currentRegion
          })} accessibilityRole="imagebutton" onPress={handleLogoPress} $_css4={styles.navHeaderStyles.logo}>
              <CpImdLogo fill={name === "dark" ? textColors.default : undefined} height={logoSize} />
            </_StyledPressable>
            {
            /* Either renders the mobile dropdown menu or the desktop navigation */
          }
            {!isNarrowScreen ? <_StyledView4 $_css5={localStyles.navMenu}>
                {menuOptions?.map(option => {
              return <CpDesktopHeaderButton handleHideDropdown={handleHideDropdown} handleShowDropdown={handleShowDropdown} icon={option?.icon} key={option.title} navGroups={option.navGroups} title={option.title} visible={dropdownOpen[option.title]} />;
            })}
              </_StyledView4> : null}
          </_StyledView3>
          <_StyledView5 $_css6={styles.navHeaderStyles.row1ButtonContainer}>
            {!isNarrowScreen ? <CpDarkModeButton type={ActionButtonUserType.NavHeader} /> : null}

            <PrActionButton actionButton="PICK_LANGUAGE_AND_REGION" type={ActionButtonUserType.NavHeader} />
            <CpAccountMenu isAdmin menuActions={defaultMenuActions} />
          </_StyledView5>
        </_StyledView2>
      </_StyledView>;
  }
};

export default CpAdminNavHeader;

var _StyledView = _styled(View).withConfig({
  displayName: "CpAdminNavHeader___StyledView",
  componentId: "sc-br5gp1-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpAdminNavHeader___StyledView2",
  componentId: "sc-br5gp1-1"
})(["", ""], p => p.$_css2);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpAdminNavHeader___StyledView3",
  componentId: "sc-br5gp1-2"
})(["", ""], p => p.$_css3);

var _StyledPressable = _styled(Pressable).withConfig({
  displayName: "CpAdminNavHeader___StyledPressable",
  componentId: "sc-br5gp1-3"
})(["", ""], p => p.$_css4);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpAdminNavHeader___StyledView4",
  componentId: "sc-br5gp1-4"
})(["", ""], p => p.$_css5);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpAdminNavHeader___StyledView5",
  componentId: "sc-br5gp1-5"
})(["", ""], p => p.$_css6);