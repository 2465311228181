import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import CpError from "components/common/CpError";
import CpModal from "components/common/CpModal";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import { css, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import translations from "translations";
const localStyles = {
  buttonContainer: css`
    flex-direction: row;
    justify-content: flex-end;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
    width: 100%;
  `,
  buttonRightSpacing: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  disclaimer: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  email: css`
    ${styles.italicText};
    ${styles.boldText};
    word-wrap: anywhere;
  `,
  errorText: css`
    color: ${({
    theme
  }) => theme.formColors.fieldError};
  `,
  icon: css`
    min-width: ${({
    theme
  }) => theme.iconSizes.large}px;
  `,
  mobileButtonSpacing: css`
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  reportName: css`
    ${styles.italicText};
    word-wrap: anywhere;
  `,
  rightMargin: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  text: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * Reusable modal component for requested CSV forms to be emailed.
 */
const CpAdminCsvConfirmationModal = ({
  error,
  isBusy,
  isOpen,
  isSubmitted,
  onClose,
  onSubmit,
  reportName
}) => {
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    buttonColors
  } = useAppTheme();
  const {
    user
  } = useImdSession();
  const renderSubmittedModal = useMemo(() => {
    return <>
        <View>
          {error ? <_StyledView $_css={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
              <View>
                <_StyledPrText $_css2={localStyles.text}>
                  <FormattedMessage id="d1kyox" defaultMessage="Sorry. We were unable to send you the requested report." />
                </_StyledPrText>
                <CpError error={error} />
              </View>
            </_StyledView> : <_StyledView2 $_css3={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
              <PrText>
                <FormattedMessage id="Lv273L" defaultMessage="Thank you, we have received your request. You should receive an email shortly with a CSV copy of the {reportName}." values={{
              reportName
            }} />
              </PrText>
            </_StyledView2>}
        </View>
        <_StyledView3 $_css4={[isWideScreen ? localStyles.buttonContainer : undefined]}>
          <_StyledPrButton onPress={onClose} type="outline" $_css5={[isWideScreen ? undefined : localStyles.mobileButtonSpacing]}>
            <_StyledPrText2 children={translations.buttons.close} $_css6={localStyles.buttonText} />
          </_StyledPrButton>
        </_StyledView3>
      </>;
  }, [error, reportName, isWideScreen, onClose]);
  return <_StyledCpModal onBackgroundPress={onClose} visible={isOpen} $_css7={styles.marginHorizontalSingle}>
      <_StyledView4 $_css8={styles.modalContainer}>
        <_StyledPrText3 $_css9={styles.modalHeaderText}>
          <FormattedMessage id="rxxZ3I" defaultMessage="CSV Request Received" />
        </_StyledPrText3>
        {isSubmitted ? renderSubmittedModal : <>
            <View>
              <PrText>
                <FormattedMessage id="HLAZUg" defaultMessage="Thank you for requesting a CSV copy of the {reportName}." values={{
              reportName: <_StyledPrText4 children={reportName} $_css10={localStyles.reportName} />
            }} />
              </PrText>
              <_StyledView5 $_css11={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
                <_StyledPrIcon fill={buttonColors.primary} icon={IconName.mail} size={iconSizes.large} $_css12={[localStyles.icon, localStyles.rightMargin]} />
                <PrText>
                  <FormattedMessage id="44i1Pb" defaultMessage="Your request is being processed. An email should be sent shortly to {email} with a copy of this report." values={{
                email: <_StyledPrText5 children={user?.email} $_css13={localStyles.email} />
              }} />
                </PrText>
              </_StyledView5>

              <_StyledPrText6 $_css14={localStyles.disclaimer}>
                <FormattedMessage id="5kf8rs" defaultMessage="The information being sent is confidential and intended for IMD Health Administrators ONLY. If you notice the email listed above is incorrect, please contact {supportEmail} immediately." values={{
              supportEmail: <_StyledPrText7 children="support@imdhealth.com" $_css15={styles.boldText} />
            }} />
              </_StyledPrText6>
            </View>
            <_StyledView6 $_css16={[isWideScreen ? localStyles.buttonContainer : undefined]}>
              <_StyledPrButton2 busy={isBusy} onPress={onSubmit} testID="CpAdminCsvConfirmationModalConfirmButton" $_css17={[isWideScreen ? localStyles.buttonRightSpacing : localStyles.mobileButtonSpacing]}>
                <_StyledPrText8 children={translations.buttons.confirm} $_css18={localStyles.buttonText} />
              </_StyledPrButton2>
              <_StyledPrButton3 onPress={onClose} type="outline" $_css19={[isWideScreen ? undefined : localStyles.mobileButtonSpacing]}>
                <_StyledPrText9 children={translations.buttons.close} $_css20={localStyles.buttonText} />
              </_StyledPrButton3>
            </_StyledView6>
          </>}
      </_StyledView4>
    </_StyledCpModal>;
};

export default CpAdminCsvConfirmationModal;

var _StyledView = _styled(View).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledView",
  componentId: "sc-a384bf-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrText",
  componentId: "sc-a384bf-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledView2",
  componentId: "sc-a384bf-2"
})(["", ""], p => p.$_css3);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledView3",
  componentId: "sc-a384bf-3"
})(["", ""], p => p.$_css4);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrButton",
  componentId: "sc-a384bf-4"
})(["", ""], p => p.$_css5);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrText2",
  componentId: "sc-a384bf-5"
})(["", ""], p => p.$_css6);

var _StyledCpModal = _styled(CpModal).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledCpModal",
  componentId: "sc-a384bf-6"
})(["", ""], p => p.$_css7);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledView4",
  componentId: "sc-a384bf-7"
})(["", ""], p => p.$_css8);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrText3",
  componentId: "sc-a384bf-8"
})(["", ""], p => p.$_css9);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrText4",
  componentId: "sc-a384bf-9"
})(["", ""], p => p.$_css10);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledView5",
  componentId: "sc-a384bf-10"
})(["", ""], p => p.$_css11);

var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrIcon",
  componentId: "sc-a384bf-11"
})(["", ""], p => p.$_css12);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrText5",
  componentId: "sc-a384bf-12"
})(["", ""], p => p.$_css13);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrText6",
  componentId: "sc-a384bf-13"
})(["", ""], p => p.$_css14);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrText7",
  componentId: "sc-a384bf-14"
})(["", ""], p => p.$_css15);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledView6",
  componentId: "sc-a384bf-15"
})(["", ""], p => p.$_css16);

var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrButton2",
  componentId: "sc-a384bf-16"
})(["", ""], p => p.$_css17);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrText8",
  componentId: "sc-a384bf-17"
})(["", ""], p => p.$_css18);

var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrButton3",
  componentId: "sc-a384bf-18"
})(["", ""], p => p.$_css19);

var _StyledPrText9 = _styled(PrText).withConfig({
  displayName: "CpAdminCsvConfirmationModal___StyledPrText9",
  componentId: "sc-a384bf-19"
})(["", ""], p => p.$_css20);