import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { css, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import translations from "translations";
const localStyles = {
  editIcon: css`
    align-items: center;
    flex: 1;
    justify-content: center;
  `,
  editIconDesktop: css`
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  experienceText: css`
    align-items: center;
    justify-content: flex-end;
  `,
  infoMenu: css`
    flex-direction: row;
  `,
  itemContainer: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    flex-direction: row;
    margin-bottom: -1px;
    overflow: hidden;
    padding: 0px;
  `,
  itemVerticalBottom: css`
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    margin-bottom: 0px;
  `,
  itemVerticalTop: css`
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
  `,
  leftMenu: css`
    flex: 2;
    justify-content: space-between;
    justify-items: center;
  `,
  lighterText: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
    text-transform: capitalize;
  `,
  listInnerContainer: css`
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  orgName: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  rightMenu: css`
    align-items: flex-end;
    flex: 1;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-vertical: ${({
    theme
  }) => theme.spacing.half}px;
    text-align: right;
  `,
  rightMenuDesktop: css`
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
  `
}; // TODO: This should be replaced with graphql type

/**
 * This component renders the list item for the orgs page
 */
const CpOrganizationsListItem = ({
  isFirst,
  isLast,
  organization
}) => {
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    buttonColors
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const navigation = useNavigation();
  const {
    _id,
    childCount,
    countryName,
    name,
    parent,
    status,
    type
  } = organization;
  const organizationType = translations.enums.organizationTypes[type];
  const handleOrganizationPress = useCallback(() => {
    navigation.navigate(Routes.organization, {
      id: _id
    });
  }, [navigation, _id]);
  return <_StyledPrPressable onPress={handleOrganizationPress} $_css={[localStyles.itemContainer, isFirst && localStyles.itemVerticalTop, isLast && localStyles.itemVerticalBottom]}>
      <_StyledView $_css2={localStyles.listInnerContainer}>
        <_StyledView2 $_css3={localStyles.leftMenu}>
          <_StyledPrText children={name} numberOfLines={2} $_css4={localStyles.orgName} />
          <_StyledView3 $_css5={localStyles.infoMenu}>
            <_StyledView4 $_css6={styles.flexSingle}>
              <_StyledPrText2 children={parent?.name} numberOfLines={1} $_css7={localStyles.lighterText} />
              {childCount ? <_StyledPrText3 children={formatMessage(translations.labels.children, {
              num: childCount
            })} numberOfLines={1} $_css8={[localStyles.lighterText, !isWideScreen ? styles.displayNone : undefined]} /> : undefined}
              <_StyledPrText4 children={status} $_css9={[localStyles.lighterText, isWideScreen ? styles.displayNone : undefined]} />
            </_StyledView4>
            {isWideScreen ? <_StyledView5 $_css10={[styles.flexSingle, localStyles.experienceText]}>
                <_StyledPrText5 // TODO: Change this when we are able and if we want to fetch experience type
            children={organizationType} numberOfLines={1} $_css11={[localStyles.lighterText, !isWideScreen ? styles.displayNone : undefined]} />
              </_StyledView5> : null}
          </_StyledView3>
        </_StyledView2>
        <_StyledView6 $_css12={[localStyles.rightMenu, isWideScreen ? localStyles.rightMenuDesktop : undefined]}>
          <View>
            <_StyledPrText6 children={status} $_css13={[localStyles.lighterText, !isWideScreen ? styles.displayNone : undefined]} />
            <_StyledPrText7 children={countryName} $_css14={[localStyles.lighterText, styles.paddingNone]} />
          </View>
          <_StyledView7 $_css15={[isWideScreen ? localStyles.editIconDesktop : localStyles.editIcon]}>
            <PrIcon fill={buttonColors.primary} icon={IconName.editPencil} size={isWideScreen ? iconSizes.small : iconSizes.medium} />
          </_StyledView7>
        </_StyledView6>
      </_StyledView>
    </_StyledPrPressable>;
};

export default CpOrganizationsListItem;

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpOrganizationsListItem___StyledPrPressable",
  componentId: "sc-1wf7fnh-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationsListItem___StyledView",
  componentId: "sc-1wf7fnh-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpOrganizationsListItem___StyledView2",
  componentId: "sc-1wf7fnh-2"
})(["", ""], p => p.$_css3);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationsListItem___StyledPrText",
  componentId: "sc-1wf7fnh-3"
})(["", ""], p => p.$_css4);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpOrganizationsListItem___StyledView3",
  componentId: "sc-1wf7fnh-4"
})(["", ""], p => p.$_css5);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpOrganizationsListItem___StyledView4",
  componentId: "sc-1wf7fnh-5"
})(["", ""], p => p.$_css6);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpOrganizationsListItem___StyledPrText2",
  componentId: "sc-1wf7fnh-6"
})(["", ""], p => p.$_css7);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpOrganizationsListItem___StyledPrText3",
  componentId: "sc-1wf7fnh-7"
})(["", ""], p => p.$_css8);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpOrganizationsListItem___StyledPrText4",
  componentId: "sc-1wf7fnh-8"
})(["", ""], p => p.$_css9);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpOrganizationsListItem___StyledView5",
  componentId: "sc-1wf7fnh-9"
})(["", ""], p => p.$_css10);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpOrganizationsListItem___StyledPrText5",
  componentId: "sc-1wf7fnh-10"
})(["", ""], p => p.$_css11);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpOrganizationsListItem___StyledView6",
  componentId: "sc-1wf7fnh-11"
})(["", ""], p => p.$_css12);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpOrganizationsListItem___StyledPrText6",
  componentId: "sc-1wf7fnh-12"
})(["", ""], p => p.$_css13);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpOrganizationsListItem___StyledPrText7",
  componentId: "sc-1wf7fnh-13"
})(["", ""], p => p.$_css14);

var _StyledView7 = _styled(View).withConfig({
  displayName: "CpOrganizationsListItem___StyledView7",
  componentId: "sc-1wf7fnh-14"
})(["", ""], p => p.$_css15);