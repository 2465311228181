import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import PrSelectSingle from "components/primitives/PrSelectSingle";
import { css, styles } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.formColors.fieldBackground};
    border-color: ${({
    theme
  }) => theme.formColors.fieldBorderInactive};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-width: 1px;
    position: relative;
  `,
  label: css`
    color: ${({
    theme
  }) => theme.formColors.fieldLabelActive};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  wrapper: css`
    background-color: ${({
    theme
  }) => theme.formColors.fieldBackground};
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    width: 100%;
  `
};

/**
 * A non-form Select component for single select.
 */
const CpSelect = ({
  onChange,
  selectOptions,
  style,
  value,
  ...otherProps
}) => {
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });
  const handleLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setInputLayout(layout);
  }, []);
  const [dropdownLayout, setDropdownLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });
  const [inputHeight, setInputHeight] = useState(0);
  useEffect(() => {
    if (!inputHeight) {
      setInputHeight(dropdownLayout.height);
    }
  }, [dropdownLayout.height, inputHeight, inputLayout.height]);
  const handleDropdownLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setDropdownLayout(layout);
  }, []);
  const wrapperStyle = useMemo(() => {
    return {
      height: inputLayout.height
    };
  }, [inputLayout]);
  const handleChange = useCallback(() => {
    onChange();
  }, [onChange]);
  return <_StyledView onLayout={handleLayout} style={wrapperStyle} $_css={localStyles.wrapper} $_css2={inputHeight}>
      <_StyledView2 onLayout={handleDropdownLayout} style={style} $_css3={[styles.formField, localStyles.container]}>
        <PrSelectSingle onChangeOption={handleChange} selectOptions={selectOptions} value={value} {...otherProps} />
      </_StyledView2>
    </_StyledView>;
};

export default CpSelect;

var _StyledView = _styled(View).withConfig({
  displayName: "CpSelect___StyledView",
  componentId: "sc-1yu6lfv-0"
})(["", ";min-height:", "px;"], p => p.$_css, p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpSelect___StyledView2",
  componentId: "sc-1yu6lfv-1"
})(["", ""], p => p.$_css3);