import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback } from "react";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { css } from "services/Theme";
import { iconSizes } from "services/Theme/common";
const localStyles = {
  checkboxContainer: css`
    align-items: center;
    flex-direction: row;
    padding: 0;
  `,
  checkboxText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  disabledText: css`
    color: ${({
    theme
  }) => theme.textColors.disabled};
  `,
  icon: css`
    /* Prevent checkbox from shrinking on smaller screens */
    min-width: ${({
    theme
  }) => theme.iconSizes.small}px;
  `
};

/**
 * Checkbox component
 */
const PrCheckbox = ({
  accessibilityLabel,
  disabled,
  label,
  onChangeOption,
  style,
  testID,
  value = false
}) => {
  const {
    textColors
  } = useAppTheme();
  const onPress = useCallback(() => {
    onChangeOption && onChangeOption(!value);
  }, [onChangeOption, value]);
  return <_StyledPrPressable accessibilityLabel={accessibilityLabel} accessibilityRole="checkbox" accessibilityState={{
    checked: value,
    disabled
  }} disabled={disabled} hoveredBackgroundColor="surface" onPress={onPress} pressedBackgroundColor="surface" style={style} testID={testID} $_css={localStyles.checkboxContainer}>
      <_StyledPrIcon fill={disabled ? textColors.disabled : textColors.default} icon={value ? IconName.checkboxChecked : IconName.checkboxUnchecked} size={iconSizes.small} $_css2={localStyles.icon} />
      <_StyledPrText $_css3={[localStyles.checkboxText, disabled ? localStyles.disabledText : undefined]}>
        {label}
      </_StyledPrText>
    </_StyledPrPressable>;
};

export default PrCheckbox;

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "PrCheckbox___StyledPrPressable",
  componentId: "sc-1vyb7ao-0"
})(["", ""], p => p.$_css);

var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "PrCheckbox___StyledPrIcon",
  componentId: "sc-1vyb7ao-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "PrCheckbox___StyledPrText",
  componentId: "sc-1vyb7ao-2"
})(["", ""], p => p.$_css3);