import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import { AdSlots } from "services/Constants";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  paddingVerticalHalf: css`
    padding-vertical: ${({
    theme
  }) => theme.spacing.quarter}px;
  `,
  spaceBetween: css`
    justify-content: space-between;
  `
};

/**
 * Renders the details of an organizations configuration regarding advertisements
 */
const CpOrganizationConfigAds = ({
  organization
}) => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const appConfig = organization?.appConfig;
  const adsHidden = appConfig?.appAdvertisement?.hideAds;
  const isEnabledOrDisabled = useCallback(isEnabled => isEnabled ? translations.labels.enabled : translations.labels.disabled, []); // Capture ad spots based on available enums (which should match backend types), and displays only if ads are enabled

  const adPlacements = useMemo(() => {
    if (adsHidden) {
      return null;
    }

    const adSpots = appConfig?.appAdvertisement?.adSpots;
    return <>
        <_StyledPrText children={translations.attributes.appConfiguration.advertisementSlots} $_css={styles.formStyles.readonlyLabel} />
        <_StyledView $_css2={[styles.rowAndCenter, styles.paddingVerticalSingle, isMediumOrSmallerScreen ? localStyles.spaceBetween : undefined]}>
          <_StyledView2 $_css3={!isMediumOrSmallerScreen ? styles.marginRightDouble : undefined}>
            {Object.keys(AdSlots).map(slot => <_StyledPrText2 children={translations.enums.adSlots[slot]} key={slot} $_css4={[styles.formStyles.readonlyText, localStyles.paddingVerticalHalf]} />)}
          </_StyledView2>
          <View>
            {Object.keys(AdSlots).map(slot => {
            const spot = adSpots?.find(adSpot => adSpot.slot === slot);
            return <_StyledPrText3 children={isEnabledOrDisabled(spot ? Object.keys(spot).length : !!spot)} key={slot} $_css5={[styles.formStyles.readonlyText, localStyles.paddingVerticalHalf]} />;
          })}
          </View>
        </_StyledView>
      </>;
  }, [adsHidden, appConfig?.appAdvertisement?.adSpots, isEnabledOrDisabled, isMediumOrSmallerScreen]);
  return <_StyledView3 $_css6={isMediumOrSmallerScreen ? styles.paddingDouble : undefined}>
      <CpReadonlyInput label={translations.attributes.appConfiguration.hideAdvertisements} value={adsHidden ? translations.labels.yes : translations.labels.no} />
      {adPlacements}
    </_StyledView3>;
};

export default CpOrganizationConfigAds;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigAds___StyledPrText",
  componentId: "sc-1ao0njd-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationConfigAds___StyledView",
  componentId: "sc-1ao0njd-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigAds___StyledView2",
  componentId: "sc-1ao0njd-2"
})(["", ""], p => p.$_css3);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigAds___StyledPrText2",
  componentId: "sc-1ao0njd-3"
})(["", ""], p => p.$_css4);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigAds___StyledPrText3",
  componentId: "sc-1ao0njd-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigAds___StyledView3",
  componentId: "sc-1ao0njd-5"
})(["", ""], p => p.$_css6);