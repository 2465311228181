var _QlOrganizationsQuery;

import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";

/**
 * Used to fetch a list of organizations for the admin app
 */
export const organizationsQuery = _QlOrganizationsQuery !== void 0 ? _QlOrganizationsQuery : _QlOrganizationsQuery = require("./__generated__/QlOrganizationsQuery.graphql");

const QlOrganizations = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={organizationsQuery} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlOrganizations;