var _QlOrganizationQuery;

import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";

/**
 * Used to fetch a single organization by its id
 * Admin app only
 */
export const QlOrganizationRequest = _QlOrganizationQuery !== void 0 ? _QlOrganizationQuery : _QlOrganizationQuery = require("./__generated__/QlOrganizationQuery.graphql");

const QlOrganization = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={QlOrganizationRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlOrganization;