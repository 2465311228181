import React from "react"
import { StyleProp, View, ViewProps } from "react-native"

import PrText from "components/primitives/PrText"
import { QlOrganizationQuery$data } from "query/__generated__/QlOrganizationQuery.graphql"

interface CpOrganizationIntegrationsProps {
  organization: QlOrganizationQuery$data["organization"]
  style?: StyleProp<ViewProps>
}

/**
 * Renders details about an organizations integrations, including information about its OauthApplication, SSO links, and other things relevant to external use.
 */
const CpOrganizationIntegrations: React.FC<CpOrganizationIntegrationsProps> = ({
  // organization,
  style,
}) => {
  return (
    <View style={style}>
      <PrText>
        This page will eventually render information and tools regarding this organizations
        integration(s). Including but not limited to information about any OauthApplications, access
        tokens, reference IDs, SSO links, and other things relevant to external use.
      </PrText>
    </View>
  )
}

export default CpOrganizationIntegrations
