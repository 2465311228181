import _styled22 from "styled-components";
import _styled21 from "styled-components";
import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import { useIntl } from "react-intl";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  column: css`
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  hideRule: css`
    ${styles.formStyles.readonlyText};
    padding: 0px;
  `,
  item: css`
    padding-vertical: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  subsection: css`
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  subsectionHeading: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: bold;
  `
};

/**
 * Renders the details of an organizations configuration regarding the application header component, including logo, search, quick actions and account menu.
 */
const CpOrganizationConfigHeader = ({
  organization
}) => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const appConfig = organization?.appConfig;
  const isEnabledOrDisabled = useCallback(isEnabled => isEnabled ? translations.labels.enabled : translations.labels.disabled, []); // Extract rule set(s) and render them

  const compileRules = useCallback(ruleSet => {
    const contentToReturn = [];

    if (ruleSet?.length) {
      ruleSet.map((rule, ruleIndex) => {
        let routes = "all routes";

        if (rule.routes.length) {
          routes = "";
          rule.routes.map((route, index) => {
            const ruleLength = rule.routes.length;
            const append = ruleLength > 1 && ruleLength !== index ? ", " : "";
            return routes += route + append;
          });
        }

        contentToReturn.push(<_StyledPrText children={`${ruleIndex + 1} - ${rule.hide ? "Hide" : "Show"} on ${routes}`} // TODO: Do better index
        // eslint-disable-next-line react/no-array-index-key
        key={`rule-${ruleIndex}`} $_css={localStyles.hideRule} />);
      });
    } else {
      // TODO: Would we ever have empty hide_rules?  Need to handle it better
      contentToReturn.push(<PrText children="TODO" />);
    }

    return <>
        <_StyledPrText2 children="Hide Rules" $_css2={[styles.formStyles.readonlyLabel, styles.marginVerticalSingle]} />
        <_StyledView $_css3={styles.marginBottomDouble}>{contentToReturn}</_StyledView>
      </>;
  }, []);
  const hideRulesHeader = useMemo(() => compileRules(appConfig?.appHeader?.hideRules), [appConfig?.appHeader?.hideRules, compileRules]);
  const hideRulesHeaderSearch = useMemo(() => compileRules(appConfig?.appHeader?.search?.hideRules), [appConfig?.appHeader?.search?.hideRules, compileRules]);
  const headerActions = useMemo(() => {
    const actions = appConfig?.appHeader?.menuActions;
    const sortedActions = actions ? [...actions].sort((a, b) => (a.rank || Infinity) - (b.rank || Infinity)) : [];
    return <_StyledView2 $_css4={styles.marginVerticalSingle}>
        <_StyledPrText3 children="Ranked Actions" $_css5={[styles.formStyles.readonlyLabel, styles.marginBottomSingle]} />
        {sortedActions.length ? <_StyledView3 $_css6={styles.rowAndCenter}>
            {
          /* TODO: These column contents will need to break/wrap for smaller screens */
        }
            <_StyledView4 $_css7={localStyles.column}>
              {sortedActions.map(action => <_StyledPrText4 children={action.rank} key={`rank-${action.rank}`} $_css8={localStyles.item} />)}
            </_StyledView4>
            <_StyledView5 $_css9={localStyles.column}>
              {sortedActions.map(action => <_StyledPrText5 children={formatMessage(translations.enums.appHeaderActions[action.actionType])} key={`type-${action.rank}`} $_css10={localStyles.item} />)}
            </_StyledView5>
            <_StyledView6 $_css11={localStyles.column}>
              {sortedActions.map(action => <_StyledPrText6 children={formatMessage(translations.enums.appHeaderPlacements[action.actionPlacement])} key={`placement-${action.rank}`} $_css12={localStyles.item} />)}
            </_StyledView6>
          </_StyledView3> : <_StyledPrText7 children={translations.labels.none} $_css13={styles.formStyles.readonlyText} />}
      </_StyledView2>;
  }, [appConfig?.appHeader?.menuActions, formatMessage]);
  return <_StyledView7 $_css14={isMediumOrSmallerScreen ? styles.paddingDouble : undefined}>
      {hideRulesHeader}
      <_StyledView8 $_css15={localStyles.subsection}>
        <_StyledPrText8 children="Header Sections" $_css16={localStyles.subsectionHeading} />
        {Object.entries(appConfig?.appHeader?.sections || {}).map((section, index) => <CpReadonlyInput fallbackValue={translations.labels.notProvided} key={section[0] ?? index} label={section[0].toUpperCase()} value={section[1]} />)}
      </_StyledView8>
      <_StyledView9 $_css17={localStyles.subsection}>
        <_StyledPrText9 children={translations.attributes.appConfiguration.search} $_css18={localStyles.subsectionHeading} />
        <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={translations.attributes.appConfiguration.placeholder} value={appConfig?.appHeader?.search?.placeholder ? `"${appConfig.appHeader.search.placeholder}"` : undefined} />
        <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={translations.attributes.appConfiguration.searchMethod} value={translations.enums.appSearchTypes[appConfig?.appHeader?.search?.searchMethod]} />
        {hideRulesHeaderSearch}
      </_StyledView9>
      <_StyledView10 $_css19={localStyles.subsection}>
        <_StyledPrText10 children="Logo Action" $_css20={localStyles.subsectionHeading} />
        <CpReadonlyInput fallbackValue={translations.labels.notProvided} label="Action" value={appConfig?.appHeader?.logoAction?.action} />
        <CpReadonlyInput fallbackValue={translations.labels.notProvided} label="Action Payload" value={appConfig?.appHeader?.logoAction?.actionPayload} />
      </_StyledView10>
      <_StyledView11 $_css21={localStyles.subsection}>
        <_StyledPrText11 children="Quick Actions and Menu" $_css22={localStyles.subsectionHeading} />
        {headerActions}
        <CpReadonlyInput label={translations.attributes.appConfiguration.hideMenu} value={isEnabledOrDisabled(appConfig?.appHeader?.hideMenu)} />
      </_StyledView11>
    </_StyledView7>;
};

export default CpOrganizationConfigHeader;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText",
  componentId: "sc-1dbgpe-0"
})(["", ""], p => p.$_css);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText2",
  componentId: "sc-1dbgpe-1"
})(["", ""], p => p.$_css2);

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView",
  componentId: "sc-1dbgpe-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView2",
  componentId: "sc-1dbgpe-3"
})(["", ""], p => p.$_css4);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText3",
  componentId: "sc-1dbgpe-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView3",
  componentId: "sc-1dbgpe-5"
})(["", ""], p => p.$_css6);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView4",
  componentId: "sc-1dbgpe-6"
})(["", ""], p => p.$_css7);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText4",
  componentId: "sc-1dbgpe-7"
})(["", ""], p => p.$_css8);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView5",
  componentId: "sc-1dbgpe-8"
})(["", ""], p => p.$_css9);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText5",
  componentId: "sc-1dbgpe-9"
})(["", ""], p => p.$_css10);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView6",
  componentId: "sc-1dbgpe-10"
})(["", ""], p => p.$_css11);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText6",
  componentId: "sc-1dbgpe-11"
})(["", ""], p => p.$_css12);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText7",
  componentId: "sc-1dbgpe-12"
})(["", ""], p => p.$_css13);

var _StyledView7 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView7",
  componentId: "sc-1dbgpe-13"
})(["", ""], p => p.$_css14);

var _StyledView8 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView8",
  componentId: "sc-1dbgpe-14"
})(["", ""], p => p.$_css15);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText8",
  componentId: "sc-1dbgpe-15"
})(["", ""], p => p.$_css16);

var _StyledView9 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView9",
  componentId: "sc-1dbgpe-16"
})(["", ""], p => p.$_css17);

var _StyledPrText9 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText9",
  componentId: "sc-1dbgpe-17"
})(["", ""], p => p.$_css18);

var _StyledView10 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView10",
  componentId: "sc-1dbgpe-18"
})(["", ""], p => p.$_css19);

var _StyledPrText10 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText10",
  componentId: "sc-1dbgpe-19"
})(["", ""], p => p.$_css20);

var _StyledView11 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledView11",
  componentId: "sc-1dbgpe-20"
})(["", ""], p => p.$_css21);

var _StyledPrText11 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHeader___StyledPrText11",
  componentId: "sc-1dbgpe-21"
})(["", ""], p => p.$_css22);