import { useReduxDevToolsExtension } from "@react-navigation/devtools";
import { useLinkTo, useNavigation } from "@react-navigation/native";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import CpAdminNavHeader from "components/app/CpAdminNavHeader";
import CpAppLoading from "components/app/CpAppLoading";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import ScChangeRegion from "components/screens/ScChangeRegion";
import ScNotFound from "components/screens/ScNotFound";
import ScSelectOrganization from "components/screens/ScSelectOrganization";
import ScViewOrganization from "components/screens/admin/ScViewOrganization";
import useImdSession from "hooks/useImdSession";
import imdLogo from "images/imdLogo.png";
import Routes from "navigators/Routes";
import createStackNavigatorWithHistory from "navigators/createStackNavigatorWithHistory";
import { Linking } from "react-native";
import Config from "services/Config";
import translations from "translations";
const ScOrganizations = React.lazy(() => import("components/screens/admin/ScOrganizations"));
const ScAccessDenied = React.lazy(() => import("components/screens/admin/ScAccessDenied"));
const ScAdminHome = React.lazy(() => import("components/screens/admin/ScAdminHome"));
const ScSigninAdmin = React.lazy(() => import("components/screens/ScSigninAdmin"));
const Stack = createStackNavigatorWithHistory();
export let UserRole;
/**
 * The root navigator sets up react navigation with the top-most navigator as a tab navigator
 * where the tab view is hidden on larger devices.
 */

(function (UserRole) {
  UserRole["Admin"] = "admin";
  UserRole["Member"] = "member";
  UserRole["Superadmin"] = "superadmin";
})(UserRole || (UserRole = {}));

const NvAdminRoot = () => {
  const {
    formatMessage
  } = useIntl();
  const {
    initialized,
    user
  } = useImdSession();
  const {
    navigate
  } = useNavigation();
  const linkTo = useLinkTo();
  const [pendingUrl, setPendingUrl] = useState(() => Linking.getInitialURL()); // TODO: Create a admin-specific context for authorization

  const navigationRef = useRef(null);
  useReduxDevToolsExtension(navigationRef);
  const renderHeader = useCallback(stackHeaderProps => {
    // This if statement prevents multiple copies of headers from being rendered in float mode when you navigate
    return <CpAdminNavHeader {...stackHeaderProps} />;
  }, []);
  const isAdmin = user?.currentMembership?.role === UserRole.Superadmin || user?.currentMembership?.role === UserRole.Admin;
  useEffect(() => {
    if (user && isAdmin && pendingUrl) {
      pendingUrl.then(initialUrl => {
        const url = initialUrl ? new URL(initialUrl) : null;

        if (!url || url.pathname === `/${Routes.signin}`) {
          navigate(Routes.home);
        } else {
          linkTo(`${url.pathname}${url.search}`);
          setPendingUrl(undefined);
        }
      });
    }
  }, [isAdmin, linkTo, navigate, pendingUrl, user]);
  const appName = formatMessage(translations.appNames[Config.APP_NAME]); // Do not render the app until imd context is initialized

  if (!initialized) return <CpAppLoading />;
  return <>
      <CpUpdatePageTitle description={translations.labels.appTagline} image={`${document.location.origin}${imdLogo}`} imageAlt={translations.labels.altImdLogo} pageType="website" title={appName} />
      <Stack.Navigator initialRouteName={user ? Routes.home : Routes.signin} screenOptions={{
      header: renderHeader,
      headerShown: Boolean(user),
      headerMode: "float",
      title: formatMessage(translations.screens.app)
    }}>
        <Stack.Screen component={ScNotFound} name={Routes.not_found} options={{
        title: formatMessage(translations.screens.not_found)
      }} />
        {!user ? <>
            <Stack.Screen component={ScSigninAdmin} name={Routes.home} options={{
          title: formatMessage(translations.screens.signin)
        }} />
            <Stack.Screen component={ScSigninAdmin} name={Routes.signin} options={{
          headerShown: false,
          title: formatMessage(translations.screens.signin)
        }} />
          </> : <>
            {isAdmin ? <>
                <Stack.Screen component={ScAdminHome} name={Routes.home} options={{
            title: formatMessage(translations.screens.home)
          }} />
                <Stack.Screen component={ScChangeRegion} name={Routes.change_region} options={{
            title: formatMessage(translations.screens.change_region)
          }} />

                <Stack.Screen component={ScOrganizations} name={Routes.organizations} options={{
            title: formatMessage(translations.screens.organizations)
          }} />
                <Stack.Screen component={ScViewOrganization} name={Routes.organization} options={{
            title: formatMessage(translations.screens.organizations)
          }} />
                <Stack.Screen component={ScSelectOrganization} name={Routes.change_clinic} options={{
            headerShown: false,
            title: formatMessage(translations.screens.home)
          }} />
              </> : <>
                <Stack.Screen component={ScAccessDenied} name={Routes.home} options={{
            headerShown: false,
            title: formatMessage(translations.screens.home)
          }} />
                <Stack.Screen component={ScSelectOrganization} name={Routes.change_clinic} options={{
            headerShown: false,
            title: formatMessage(translations.screens.home)
          }} />
              </>}
          </>}
      </Stack.Navigator>
    </>;
};

export default memo(NvAdminRoot);