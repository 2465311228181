import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Linking, ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpLinkButton from "components/common/CpLinkButton";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import usePersistentStorage from "hooks/usePersistentStorage";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  accessDeniedContainer: css`
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  button: css`
    margin-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  emailButton: css`
    align-items: center;
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    justify-content: center;
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  linkText: css`
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  providerButton: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  text: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  topText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    font-weight: bold;
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * The access denied page for when you're trying to access the admin app with non-admin/superadmin role.
 */

const CpAdminAccessDenied = () => {
  const {
    signout,
    user
  } = useImdSession();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const [, updateCurrentMembershipId] = usePersistentStorage("membershipId");
  const [, updateCurrentOrganizationId] = usePersistentStorage("organizationId");
  const navigation = useNavigation();
  const [busy, setBusy] = useState(false);
  const handleSignoutPress = useCallback(() => {
    setBusy(true);
    signout().then(() => {
      navigation.navigate(Routes.signin);
    });
  }, [navigation, signout]);
  const handleProviderPress = useCallback(() => {
    Linking.openURL("https://app.imdhealth.com/");
  }, []);
  const handlePickerPress = useCallback(() => {
    updateCurrentMembershipId(undefined);
    updateCurrentOrganizationId(undefined);
  }, [updateCurrentMembershipId, updateCurrentOrganizationId]);
  const membershipName = user?.currentMembership?.organization.name;
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <CpUpdatePageTitle title={translations.labels.accessDenied} />

      <ScrollView contentContainerStyle={rnStyles.flexSingle}>
        <CpBackgroundHeader childStyle={styles.flexSingle} header={translations.labels.accessDenied}>
          <_StyledView $_css2={[localStyles.accessDeniedContainer, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
            <_StyledPrText $_css3={[localStyles.text, localStyles.topText]}>
              <FormattedMessage id="cNszj2" defaultMessage="Administrative privileges are required to access this application." />
            </_StyledPrText>
            <_StyledPrText2 $_css4={localStyles.text}>
              <FormattedMessage id="wjh5B2" defaultMessage="Are you a Healthcare Professional looking to log in and view content?" />
            </_StyledPrText2>

            <_StyledPrButton onPress={handleProviderPress} type="outline" $_css5={localStyles.providerButton}>
              <_StyledPrText3 $_css6={localStyles.buttonText}>
                <FormattedMessage id="4hkCak" defaultMessage="IMD Health for Healthcare Professionals" />
              </_StyledPrText3>
            </_StyledPrButton>

            <_StyledPrText4 $_css7={localStyles.text}>
              {membershipName ? <FormattedMessage id="ifE7RC" defaultMessage="Your current membership does not have admin access: {membershipName}" values={{
              membershipName
            }} /> : <FormattedMessage id="agOpuO" defaultMessage="You have not selected a membership. Please select an admin membership to continue." />}
            </_StyledPrText4>

            <_StyledPrButton2 onPress={handlePickerPress} type="outline" $_css8={localStyles.button}>
              <_StyledPrText5 children={translations.buttons.changeMembership} $_css9={localStyles.buttonText} />
            </_StyledPrButton2>

            <_StyledPrText6 $_css10={localStyles.text}>
              <FormattedMessage id="67pGjS" defaultMessage="If you feel this is in error, or would like to request access, please contact the IMD Health Support Team." />
            </_StyledPrText6>
            <_StyledCpLinkButton url="mailto:support@imdhealth.com" $_css11={localStyles.emailButton}>
              <_StyledPrText7 $_css12={localStyles.linkText}>support@imdhealth.com</_StyledPrText7>
            </_StyledCpLinkButton>
            <_StyledPrButton3 busy={busy} onPress={handleSignoutPress} $_css13={localStyles.button}>
              <_StyledPrText8 children={translations.buttons.signout} $_css14={localStyles.buttonText} />
            </_StyledPrButton3>
          </_StyledView>
        </CpBackgroundHeader>
      </ScrollView>
    </_StyledSafeAreaView>;
};

export default CpAdminAccessDenied;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "CpAdminAccessDenied___StyledSafeAreaView",
  componentId: "sc-aga2di-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpAdminAccessDenied___StyledView",
  componentId: "sc-aga2di-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrText",
  componentId: "sc-aga2di-2"
})(["", ""], p => p.$_css3);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrText2",
  componentId: "sc-aga2di-3"
})(["", ""], p => p.$_css4);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrButton",
  componentId: "sc-aga2di-4"
})(["", ""], p => p.$_css5);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrText3",
  componentId: "sc-aga2di-5"
})(["", ""], p => p.$_css6);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrText4",
  componentId: "sc-aga2di-6"
})(["", ""], p => p.$_css7);

var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrButton2",
  componentId: "sc-aga2di-7"
})(["", ""], p => p.$_css8);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrText5",
  componentId: "sc-aga2di-8"
})(["", ""], p => p.$_css9);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrText6",
  componentId: "sc-aga2di-9"
})(["", ""], p => p.$_css10);

var _StyledCpLinkButton = _styled(CpLinkButton).withConfig({
  displayName: "CpAdminAccessDenied___StyledCpLinkButton",
  componentId: "sc-aga2di-10"
})(["", ""], p => p.$_css11);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrText7",
  componentId: "sc-aga2di-11"
})(["", ""], p => p.$_css12);

var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrButton3",
  componentId: "sc-aga2di-12"
})(["", ""], p => p.$_css13);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpAdminAccessDenied___StyledPrText8",
  componentId: "sc-aga2di-13"
})(["", ""], p => p.$_css14);