import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { ScrollView, View } from "react-native";
import CpModal from "components/common/CpModal";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
import CpOrganizationConfigTabDetails from "./CpOrganizationConfigTabDetails";
const localStyles = {
  column: css`
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  item: css`
    padding-vertical: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  subsection: css`
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `
};

/**
 * Renders the details of an organizations configuration regarding the home page, including search, header, tagline and tab details.
 */
const CpOrganizationConfigHome = ({
  organization
}) => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const appConfig = organization?.appConfig;
  const [showTabModal, setShowTabModal] = useState(false);
  const handleShowTabModal = useCallback(() => setShowTabModal(true), []);
  const handleHideTabModal = useCallback(() => setShowTabModal(false), []);
  const isEnabledOrDisabled = useCallback(isEnabled => isEnabled ? translations.labels.enabled : translations.labels.disabled, []);
  const appTabs = useMemo(() => {
    return appConfig?.appHome?.tabs ?? [];
  }, [appConfig]);
  const tabs = useMemo(() => {
    return <_StyledView $_css={styles.row}>
        <_StyledView2 $_css2={localStyles.column}>
          {appTabs.map(tab => <_StyledPrText children={translations.enums.appTabTypes[tab.type]} key={`${tab._id}-type`} $_css3={localStyles.item} />)}
        </_StyledView2>
        <_StyledView3 $_css4={localStyles.column}>
          {appTabs.map(tab => <_StyledPrText2 children={tab.tabLabel} key={`${tab._id}-label`} $_css5={localStyles.item} />)}
        </_StyledView3>
      </_StyledView>;
  }, [appTabs]);
  return <>
      <_StyledView4 $_css6={isMediumOrSmallerScreen ? styles.paddingDouble : undefined}>
        <CpReadonlyInput label={translations.labels.hide} value={isEnabledOrDisabled(appConfig?.appHome?.hide)} />
        {!appConfig?.appHome?.hide ? <>
            <CpReadonlyInput label={translations.attributes.appConfiguration.hideBacksplash} value={isEnabledOrDisabled(appConfig?.appHome?.hideBacksplash)} />
            <CpReadonlyInput label={translations.attributes.appConfiguration.hideSearch} value={isEnabledOrDisabled(appConfig?.appHome?.hideSearch)} />
            <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={translations.attributes.appConfiguration.header} value={appConfig?.appHome?.header ? `"${appConfig.appHome.header}"` : undefined} />
            <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={translations.attributes.appConfiguration.tagline} value={appConfig?.appHome?.tagline ? `"${appConfig.appHome.tagline}"` : undefined} />
          </> : null}
        <_StyledView5 $_css7={localStyles.subsection}>
          <CpReadonlyInput label={translations.attributes.appConfiguration.tabPosition} // TODO: Unlikely to have multiple positions, but if we ever encounter that this will need to become more robust
        value={translations.enums.appTabPositions[appConfig?.appHome?.tabPositionRules?.[0]?.position]} />
          <CpReadonlyInput label={translations.attributes.appConfiguration.hideBarIfOneTab} value={isEnabledOrDisabled(appConfig?.appHome?.hideBarIfOneTab)} />
          <_StyledPrText3 children={translations.attributes.appConfiguration.tabs} $_css8={styles.formStyles.readonlyLabel} />
          {tabs}
          <_StyledPrButton onPress={handleShowTabModal} type="outline" $_css9={styles.marginVerticalDouble}>
            <_StyledPrText4 children={translations.buttons.details} $_css10={styles.buttonText} />
          </_StyledPrButton>
        </_StyledView5>
      </_StyledView4>

      <CpModal onBackgroundPress={handleHideTabModal} visible={showTabModal}>
        <_StyledPrText5 children="Tab Details" $_css11={styles.modalHeaderText} />
        <_StyledScrollView contentContainerStyle={rnStyles.flexSingle} $_css12={styles.scrollModal}>
          <CpOrganizationConfigTabDetails // TODO: Fix typing of this by splitting out query into fragments
        tabs={appTabs} />
          <PrButton onPress={handleHideTabModal} type="text">
            <_StyledPrText6 children={translations.buttons.close} $_css13={styles.buttonText} />
          </PrButton>
        </_StyledScrollView>
      </CpModal>
    </>;
};

export default CpOrganizationConfigHome;

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHome___StyledView",
  componentId: "sc-13f7964-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHome___StyledView2",
  componentId: "sc-13f7964-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHome___StyledPrText",
  componentId: "sc-13f7964-2"
})(["", ""], p => p.$_css3);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHome___StyledView3",
  componentId: "sc-13f7964-3"
})(["", ""], p => p.$_css4);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHome___StyledPrText2",
  componentId: "sc-13f7964-4"
})(["", ""], p => p.$_css5);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHome___StyledView4",
  componentId: "sc-13f7964-5"
})(["", ""], p => p.$_css6);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigHome___StyledView5",
  componentId: "sc-13f7964-6"
})(["", ""], p => p.$_css7);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHome___StyledPrText3",
  componentId: "sc-13f7964-7"
})(["", ""], p => p.$_css8);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpOrganizationConfigHome___StyledPrButton",
  componentId: "sc-13f7964-8"
})(["", ""], p => p.$_css9);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHome___StyledPrText4",
  componentId: "sc-13f7964-9"
})(["", ""], p => p.$_css10);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHome___StyledPrText5",
  componentId: "sc-13f7964-10"
})(["", ""], p => p.$_css11);

var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpOrganizationConfigHome___StyledScrollView",
  componentId: "sc-13f7964-11"
})(["", ""], p => p.$_css12);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigHome___StyledPrText6",
  componentId: "sc-13f7964-12"
})(["", ""], p => p.$_css13);