import _styled from "styled-components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpAdminAccessDenied from "components/common/CpAdminAccessDenied";
import CpSelectOrganization from "components/organization/CpSelectOrganization";
import useAppBackend from "hooks/useAppBackend";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * The access denied page for when you're trying to access the admin app with non-admin/superadmin role.
 */

const ScAccessDenied = () => {
  const {
    persistentHeaders: {
      membershipId
    }
  } = useAppBackend();
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <CpUpdatePageTitle title={translations.labels.accessDenied} />
      {!membershipId ? <CpSelectOrganization headerMessage={<FormattedMessage id="NSTbrX" defaultMessage="Please select an appropriate admininstrator membership" />} /> : <CpAdminAccessDenied />}
    </_StyledSafeAreaView>;
};

export default ScAccessDenied;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScAccessDenied___StyledSafeAreaView",
  componentId: "sc-1ffrfsh-0"
})(["", ""], p => p.$_css);