import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import PrText from "components/primitives/PrText";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * The home page for the admin app.
 */
const ScAdminHome = ({
  route
}) => {
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <_StyledView $_css2={styles.flexAndCenter}>
        <_StyledPrText children={{
        message: translations.labels.youAreViewing,
        values: {
          region: "Admin"
        }
      }} $_css3={styles.flexAndCenter} />
      </_StyledView>
    </_StyledSafeAreaView>;
};

export default ScAdminHome;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScAdminHome___StyledSafeAreaView",
  componentId: "sc-qmt2xk-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "ScAdminHome___StyledView",
  componentId: "sc-qmt2xk-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "ScAdminHome___StyledPrText",
  componentId: "sc-qmt2xk-2"
})(["", ""], p => p.$_css3);