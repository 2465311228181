import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import { styles } from "services/Theme";
import translations from "translations";
import useScreenLayout from "hooks/useScreenLayout";

/**
 * Renders the basic details of an organizations configuration
 */
const CpOrganizationConfigDefault = ({
  organization
}) => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const appConfig = organization?.appConfig;
  return <_StyledView $_css={isMediumOrSmallerScreen ? styles.paddingDouble : undefined}>
      <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={translations.attributes.appConfiguration.showProfessionalContent} value={appConfig?.showingProfessionalContent ? translations.labels.yes : translations.labels.no} />
    </_StyledView>;
};

export default CpOrganizationConfigDefault;

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationConfigDefault___StyledView",
  componentId: "sc-swrp9o-0"
})(["", ""], p => p.$_css);