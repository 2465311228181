import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import CpMenuDropdown from "components/common/CpMenuDropdown";
import CpPopup from "components/common/CpPopup";
import PrButton from "components/primitives/PrButton";
import PrIcon from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { css, styles } from "services/Theme";
const localStyles = {
  languageButton: css`
    align-items: center;
    justify-content: center;
    min-width: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  languageButtonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    color: ${({
    theme
  }) => theme.brandColors.primary};
  `,
  popupBorder: css`
    border: 2px solid ${({
    theme
  }) => theme.buttonColors.primary};
  `
};

/**
 * Header button container for header navigation on desktop
 */
const CpDesktopHeaderButton = ({
  handleHideDropdown,
  handleShowDropdown,
  icon,
  navGroups,
  title,
  visible
}) => {
  const {
    brandColors,
    iconSizes
  } = useAppTheme();
  const [dropdownPopupLayout, setDropdownPopupLayout] = useState({
    width: 0,
    x: 0,
    y: 0
  });
  const handleDropdownLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setDropdownPopupLayout({
      width: 270,
      x: layout.left,
      y: layout.top + layout.height
    });
  }, [setDropdownPopupLayout]);
  const handleShowDropdownButton = useCallback(() => {
    handleShowDropdown(title);
  }, [handleShowDropdown, title]);
  const handleHideDropdownButton = useCallback(() => {
    handleHideDropdown(title);
  }, [handleHideDropdown, title]);
  return <_StyledPrButton onLayout={handleDropdownLayout} onPress={handleShowDropdownButton} type="text" $_css={localStyles.languageButton}>
      <_StyledView $_css2={styles.center}>
        {icon ? <PrIcon fill={brandColors.primary} icon={icon} size={iconSizes.medium} /> : null}
        <_StyledPrText children={title} $_css3={[localStyles.languageButtonText, styles.capitalise]} />
      </_StyledView>
      <CpPopup layout={dropdownPopupLayout} onBackgroundPress={handleHideDropdownButton} popupStyle={styles.popupBorder} visible={visible}>
        <_StyledView2 $_css4={styles.paddingVerticalDouble}>
          {navGroups?.map(navGroup => <CpMenuDropdown group={navGroup} key={navGroup.title ? navGroup.title : navGroup.options[0].name} />)}
        </_StyledView2>
      </CpPopup>
    </_StyledPrButton>;
};

export default CpDesktopHeaderButton;

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpDesktopHeaderButton___StyledPrButton",
  componentId: "sc-blcuo8-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpDesktopHeaderButton___StyledView",
  componentId: "sc-blcuo8-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpDesktopHeaderButton___StyledPrText",
  componentId: "sc-blcuo8-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpDesktopHeaderButton___StyledView2",
  componentId: "sc-blcuo8-3"
})(["", ""], p => p.$_css4);