import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useCallback, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import CpAppLoading from "components/app/CpAppLoading";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpScroll from "components/common/CpScroll";
import CpTabView from "components/common/CpTabView";
import CpOrganizationConfig from "components/organization/admin/organizations/CpOrganizationConfig";
import CpOrganizationDetails from "components/organization/admin/organizations/CpOrganizationDetails";
import CpOrganizationIntegrations from "components/organization/admin/organizations/CpOrganizationIntegrations";
import useScreenLayout from "hooks/useScreenLayout";
import QlOrganization from "query/QlOrganization";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  component: css`
    background: ${({
    theme
  }) => theme.brandColors.surface};
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  tabContainer: css`
    flex: 1;
    /* Ensures the header is in full display */
    margin-top: 85px;
  `
};
const localTranslations = defineMessages({
  integrations: {
    id: "aCNei7",
    defaultMessage: "Integrations"
  }
});
/**
 * Container that fetches an organization, and manages page tab state
 */

const CtOrganizationView = () => {
  const {
    setParams
  } = useNavigation();
  const {
    params
  } = useRoute();
  const {
    formatMessage
  } = useIntl();
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const {
    id: organizationId,
    tab = "0"
  } = params;
  const handleChangeTab = useCallback((_tab, index) => {
    setParams({
      tab: `${index}`
    });
  }, [setParams]);
  const handleTabConfig = useCallback(organization => {
    const componentStyle = [localStyles.component, !isMediumOrSmallerScreen ? styles.limitedLargeScreenWidth : undefined];
    return [{
      component: <_StyledCpOrganizationDetails organization={organization} $_css={componentStyle} />,
      key: "DETAILS",
      label: formatMessage(translations.labels.details)
    }, {
      component: <_StyledCpOrganizationConfig organization={organization} $_css2={componentStyle} />,
      key: "CONFIG",
      label: formatMessage(translations.attributes.appConfiguration.configuration)
    }, {
      component: <_StyledCpOrganizationIntegrations organization={organization} $_css3={componentStyle} />,
      key: "INTEGRATION",
      label: formatMessage(localTranslations.integrations)
    }];
  }, [formatMessage, isMediumOrSmallerScreen]);
  const queryVariables = useMemo(() => ({
    id: organizationId
  }), [organizationId]);
  return <CpScroll contentContainerStyle={rnStyles.flexSingle}>
      <QlOrganization variables={queryVariables}>
        {response => {
        if (response.organization) {
          const tabConfig = handleTabConfig(response.organization);
          return <>
                <CpBackgroundHeader header={response.organization.name} />
                <_StyledCpTabView onPress={handleChangeTab} tabConfig={tabConfig} tabIndex={tab ? parseInt(tab, 10) : undefined} $_css4={localStyles.tabContainer} />
              </>;
        }

        return <CpAppLoading />;
      }}
      </QlOrganization>
    </CpScroll>;
};

export default CtOrganizationView;

var _StyledCpOrganizationDetails = _styled(CpOrganizationDetails).withConfig({
  displayName: "CtOrganizationView___StyledCpOrganizationDetails",
  componentId: "sc-si15qr-0"
})(["", ""], p => p.$_css);

var _StyledCpOrganizationConfig = _styled(CpOrganizationConfig).withConfig({
  displayName: "CtOrganizationView___StyledCpOrganizationConfig",
  componentId: "sc-si15qr-1"
})(["", ""], p => p.$_css2);

var _StyledCpOrganizationIntegrations = _styled(CpOrganizationIntegrations).withConfig({
  displayName: "CtOrganizationView___StyledCpOrganizationIntegrations",
  componentId: "sc-si15qr-2"
})(["", ""], p => p.$_css3);

var _StyledCpTabView = _styled(CpTabView).withConfig({
  displayName: "CtOrganizationView___StyledCpTabView",
  componentId: "sc-si15qr-3"
})(["", ""], p => p.$_css4);