// For some reason this only works if I put it here. .d.ts files don't seem to work in this package.
/// <reference types="styled-components/cssprop" />

import { AppRegistry } from "react-native"

import AdminApp from "AdminApp"
import "./index.css"

AppRegistry.registerComponent("App", () => AdminApp)
AppRegistry.runApplication("App", {
  rootTag: document.getElementById("react-root"),
})
