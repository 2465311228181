import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpOrganizationsCsvMutation;

import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { graphql, useMutation } from "react-relay";
import CpAdminCsvConfirmationModal from "components/common/CpAdminCsvConfirmationModal";
import CpError from "components/common/CpError";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import Log from "services/Log";
import { css } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import translations from "translations";
const sendCsvEmailMutation = _CpOrganizationsCsvMutation !== void 0 ? _CpOrganizationsCsvMutation : _CpOrganizationsCsvMutation = require("./__generated__/CpOrganizationsCsvMutation.graphql");
const localStyles = {
  button: css`
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  buttonText: css`
    align-items: center;
    display: flex;
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    justify-content: center;
  `,
  iconSeparator: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * Component holding the organizations CSV emailing button and modal
 */
const CpOrganizationsCsv = ({
  name,
  status
}) => {
  const {
    user
  } = useImdSession();
  const {
    formatMessage
  } = useIntl();
  const {
    buttonColors
  } = useAppTheme();
  const [csvError, setCsvError] = useState("");
  const [csvSubmitted, setCsvSubmitted] = useState(false);
  const [csvModalVisible, setCsvModalVisible] = useState(false);
  const handleOpenCsvModal = useCallback(() => {
    setCsvModalVisible(true);
  }, []);
  const handleCloseCsvModal = useCallback(() => {
    setCsvModalVisible(false);
  }, []);
  const [submitCsvEmail, isSubmittingCsvEmail] = useMutation(sendCsvEmailMutation);
  useEffect(() => {
    setCsvError("");
    setCsvSubmitted(false);
  }, [csvModalVisible]);
  const handleSubmitCsvEmail = useCallback(() => {
    Log.info("CpOrganizationsCsv - Sending CSV to email: ", {
      email: user?.email
    });
    submitCsvEmail({
      onCompleted: ({
        emailOrganizationsCsv
      }) => {
        if (!emailOrganizationsCsv) {
          setCsvError("Error sending CSV");
          setCsvSubmitted(true);
          Log.error("CpOrganizationsCsv - Error sending CSV");
        } else {
          setCsvSubmitted(true);
        }
      },
      onError: err => {
        setCsvError(err.toString());
        setCsvSubmitted(true);
        Log.error(`CpOrganizationsCsv - ${err}`);
      },
      variables: {
        filter: {
          name,
          status
        }
      }
    });
  }, [name, status, submitCsvEmail, user?.email]); // Doesn't return any of the CSV component if user email doesn't exist

  if (!user?.email) {
    return <CpError error={translations.errors.unauthorized} />;
  }

  return <>
      <_StyledPrButton onPress={handleOpenCsvModal} testID="CpOrganizationsCsvButton" type="outline" $_css={localStyles.button}>
        <_StyledPrText $_css2={localStyles.buttonText}>
          <_StyledPrIcon fill={buttonColors.primary} icon={IconName.mail} size={iconSizes.small} $_css3={localStyles.iconSeparator} />
          {formatMessage(translations.buttons.emailCSV)}
        </_StyledPrText>
      </_StyledPrButton>
      <CpAdminCsvConfirmationModal error={csvError} isBusy={isSubmittingCsvEmail} isOpen={csvModalVisible} isSubmitted={csvSubmitted} onClose={handleCloseCsvModal} onSubmit={handleSubmitCsvEmail} reportName="Organizations Report" />
    </>;
};

export default CpOrganizationsCsv;

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpOrganizationsCsv___StyledPrButton",
  componentId: "sc-1bmwkrh-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationsCsv___StyledPrText",
  componentId: "sc-1bmwkrh-1"
})(["", ""], p => p.$_css2);

var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpOrganizationsCsv___StyledPrIcon",
  componentId: "sc-1bmwkrh-2"
})(["", ""], p => p.$_css3);