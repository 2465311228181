import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import CpFabButton from "components/common/CpFabButton";
import CpPagination from "components/common/CpPagination";
import CtApiSuspense from "components/containers/CtApiSuspense";
import CpOrganizationsCsv from "components/organization/admin/CpOrganizationsCsv";
import CpOrganizationsFilters from "components/organization/admin/CpOrganizationsFilters";
import CpOrganizationsListItem from "components/organization/admin/CpOrganizationsListItem";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import QlOrganizations from "query/QlOrganizations";
import { useBackendSupportedLocale } from "services/Intl";
import Log from "services/Log";
import { css, rnStyles, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import translations from "translations";
const localStyles = {
  button: css`
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  buttonText: css`
    align-items: center;
    display: flex;
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    justify-content: center;
  `,
  checkbox: css`
    padding-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  container: css`
    background: ${({
    theme
  }) => theme.brandColors.surface};
    min-height: 90vh;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  csvButtonContainer: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.quadruple}px;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  desktopNewButtonContainer: css`
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  filters: css`
    z-index: ${({
    theme
  }) => theme.layers.secondLayer};
  `,
  header: css`
    padding-left: 0;
  `,
  iconSeparator: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  newButtonContainer: css`
    align-items: flex-end;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  paginationContainer: css`
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    z-index: ${({
    theme
  }) => theme.layers.firstLayer};
  `,
  paginationPaddingDesktop: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  resultText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-vertical: ${({
    theme
  }) => theme.spacing.half}px;
    text-align: center;
  `,
  screenMobilePaddingBottom: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.quadruple * 3}px;
  `,
  title: css`
    flex-direction: row;
    justify-content: space-between;
  `
};
/**
 * Fetches a paginated list of organizations based on a users search query and optional filters.
 * Allows a user to request a CSV copy of the search results to be sent to their email.
 */

const CtOrganizations = ({
  style
}) => {
  const {
    buttonColors
  } = useAppTheme();
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const locale = useBackendSupportedLocale(); // Query Variables states

  const [searchText, setSearchText] = useState("");
  const [inactive, setInactive] = useState(false); // TODO: Add experiences and regions states when we have the implementation done.

  const [currentPage, setCurrentPage] = useState(1); // Reset the page to page 1 when we change our search params

  useEffect(() => {
    setCurrentPage(1);
  }, [searchText, inactive]);
  const handleChangePagination = useCallback(page => {
    setCurrentPage(page);
  }, []);
  const renderNewButton = useMemo(() => {
    return <_StyledPrButton $_css={localStyles.button}>
        <_StyledPrText $_css2={localStyles.buttonText}>
          <_StyledPrIcon fill={buttonColors.surface} icon={IconName.add} size={iconSizes.small} $_css3={localStyles.iconSeparator} />
          {formatMessage(translations.buttons.new)}
        </_StyledPrText>
      </_StyledPrButton>;
  }, [buttonColors.surface, formatMessage]);
  const renderOrganizations = useCallback(response => {
    if (!response?.organizations?.nodes?.length) {
      return <_StyledPrText2 $_css4={styles.noResults}>
            <FormattedMessage id="A0sDz4" defaultMessage="Your search for {searchText} returned no results. Modify your search and try again." values={{
          searchText: `"${searchText}"`
        }} />
          </_StyledPrText2>;
    }

    return response?.organizations?.nodes ? <View>
          <_StyledView $_css5={[localStyles.paginationContainer, isWideScreen ? localStyles.paginationPaddingDesktop : undefined]}>
            <CpPagination currentPage={currentPage} onPageChange={handleChangePagination} pageSize={response.organizations.pageSize} siblingCount={3} totalEntries={response.organizations.totalCount} />
            <View>
              <_StyledPrText3 children={formatMessage(translations.labels.results, {
            num: response.organizations.totalCount
          })} $_css6={localStyles.resultText} />
            </View>
          </_StyledView>
          {!isWideScreen ? <_StyledView2 $_css7={localStyles.csvButtonContainer}>
              <CpOrganizationsCsv name={searchText} status={inactive ? "" : "active"} />
            </_StyledView2> : undefined}
          {response.organizations.nodes.map((org, index) => org ? <CpOrganizationsListItem isFirst={index === 0} isLast={!!(response?.organizations?.nodes && index === response.organizations.nodes.length - 1)} key={org._id} organization={org} /> : undefined)}
        </View> : null;
  }, [currentPage, formatMessage, handleChangePagination, inactive, isWideScreen, searchText]);
  const queryVariables = useMemo(() => ({
    filter: {
      name: searchText,
      status: inactive ? "" : "active"
    },
    first: 25,
    locale,
    number: currentPage
  }), [searchText, inactive, locale, currentPage]);
  return <>
      <ScrollView contentContainerStyle={rnStyles.flexSingle} style={style}>
        <_StyledView3 $_css8={[localStyles.container, styles.screen, !isWideScreen ? localStyles.screenMobilePaddingBottom : undefined]}>
          <_StyledView4 $_css9={[isWideScreen ? styles.adminLimitedLargeScreenWidth : undefined]}>
            <_StyledView5 $_css10={localStyles.title}>
              <_StyledPrText4 children={translations.labels.organizations} $_css11={[styles.pageHeading, localStyles.header]} />
              <_StyledView6 $_css12={localStyles.desktopNewButtonContainer}>
                {isWideScreen ? renderNewButton : undefined}
              </_StyledView6>
            </_StyledView5>
            <_StyledView7 $_css13={localStyles.filters}>
              <CpOrganizationsFilters inactive={inactive} onChangeInactive={setInactive} onChangeSearchText={setSearchText} searchText={searchText} />
            </_StyledView7>
            <CtApiSuspense>
              <QlOrganizations variables={queryVariables}>
                {response => response ? renderOrganizations(response) : null}
              </QlOrganizations>
            </CtApiSuspense>
          </_StyledView4>
        </_StyledView3>
      </ScrollView>
      {!isWideScreen ? <_StyledCpFabButton icon={IconName.add} label={translations.buttons.new} onPress={() => {
      // TODO: Not implemented yet
      Log.warn("CtOrganizations - Callback not yet handled. Shortcut story: #6121");
    }} $_css14={styles.fab} /> : undefined}
    </>;
};

export default CtOrganizations;

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CtOrganizations___StyledPrButton",
  componentId: "sc-1ulhgqv-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CtOrganizations___StyledPrText",
  componentId: "sc-1ulhgqv-1"
})(["", ""], p => p.$_css2);

var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CtOrganizations___StyledPrIcon",
  componentId: "sc-1ulhgqv-2"
})(["", ""], p => p.$_css3);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CtOrganizations___StyledPrText2",
  componentId: "sc-1ulhgqv-3"
})(["", ""], p => p.$_css4);

var _StyledView = _styled(View).withConfig({
  displayName: "CtOrganizations___StyledView",
  componentId: "sc-1ulhgqv-4"
})(["", ""], p => p.$_css5);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CtOrganizations___StyledPrText3",
  componentId: "sc-1ulhgqv-5"
})(["", ""], p => p.$_css6);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CtOrganizations___StyledView2",
  componentId: "sc-1ulhgqv-6"
})(["", ""], p => p.$_css7);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CtOrganizations___StyledView3",
  componentId: "sc-1ulhgqv-7"
})(["", ""], p => p.$_css8);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CtOrganizations___StyledView4",
  componentId: "sc-1ulhgqv-8"
})(["", ""], p => p.$_css9);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CtOrganizations___StyledView5",
  componentId: "sc-1ulhgqv-9"
})(["", ""], p => p.$_css10);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CtOrganizations___StyledPrText4",
  componentId: "sc-1ulhgqv-10"
})(["", ""], p => p.$_css11);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CtOrganizations___StyledView6",
  componentId: "sc-1ulhgqv-11"
})(["", ""], p => p.$_css12);

var _StyledView7 = _styled(View).withConfig({
  displayName: "CtOrganizations___StyledView7",
  componentId: "sc-1ulhgqv-12"
})(["", ""], p => p.$_css13);

var _StyledCpFabButton = _styled(CpFabButton).withConfig({
  displayName: "CtOrganizations___StyledCpFabButton",
  componentId: "sc-1ulhgqv-13"
})(["", ""], p => p.$_css14);