/**
 * @generated SignedSource<<ab8013c52ad86f89335989f2b41b8198>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type OrganizationFiltersInput = {
  name?: string | null;
  status?: string | null;
  text?: string | null;
  type?: string | null;
};
export type QlOrganizationsQuery$variables = {
  filter: OrganizationFiltersInput;
  first: number;
  locale?: Locale | null;
};
export type QlOrganizationsQuery$data = {
  readonly organizations: {
    readonly nodes: ReadonlyArray<{
      readonly _id: string;
      readonly childCount: number;
      readonly countryName: string;
      readonly name: string;
      readonly parent: {
        readonly name: string;
      } | null;
      readonly status: string;
      readonly type: string;
    } | null> | null;
    readonly pageSize: number;
    readonly totalCount: number;
  } | null;
};
export type QlOrganizationsQuery = {
  response: QlOrganizationsQuery$data;
  variables: QlOrganizationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "childCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "locale",
      "variableName": "locale"
    }
  ],
  "kind": "ScalarField",
  "name": "countryName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageSize",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QlOrganizationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationConnection",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QlOrganizationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationConnection",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2361ec6ccedf51102ee4e1df4ab94041",
    "id": null,
    "metadata": {},
    "name": "QlOrganizationsQuery",
    "operationKind": "query",
    "text": "query QlOrganizationsQuery(\n  $filter: OrganizationFiltersInput!\n  $first: Int!\n  $locale: Locale\n) {\n  organizations(filter: $filter, first: $first) {\n    nodes {\n      _id\n      childCount\n      countryName(locale: $locale)\n      name\n      parent {\n        name\n        id\n      }\n      status\n      type\n      id\n    }\n    pageSize\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "6237a652b6dcc977af415f5fd0d5a4a9";

export default node;
