import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useState } from "react";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { View } from "react-native";
import { css, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
const largeScreenStyles = {
  sectionHeaderLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    font-weight: bold;
    padding-top: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  sectionHeaderLineAccent: css`
    background-color: ${({
    theme
  }) => theme.brandColors.primary};
    height: 2px;
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
    width: 100px;
  `
};
const smallScreenStyles = {
  bottomBorder: css`
    border-bottom-width: 1px;
  `,
  sectionAccordion: css`
    align-items: center;
    border-color: ${({
    theme
  }) => theme.brandColors.primary};
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
    justify-content: space-between;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  sectionAccordionLabel: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
  `,
  topBorder: css`
    border-top-width: 1px;
  `
};

/**
 * Renders a collapsible accordion section for smaller screens, and a stylized header for larger screens.
 * Handles visible state for smaller screens.
 * TODO: This should be used instead of inline code in CpQuickResource
 */
const CpSection = ({
  children,
  isFirstItem,
  isLastItem,
  label,
  onPress,
  style,
  visibleByDefault = false
}) => {
  const {
    brandColors
  } = useAppTheme();
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const [visible, setVisible] = useState(isMediumOrSmallerScreen ? visibleByDefault : true);
  const handlePress = useCallback(() => {
    setVisible(prevVisibleState => !prevVisibleState);
    onPress && onPress();
  }, [onPress]);
  return <>
      {isMediumOrSmallerScreen ? <_StyledPrPressable accessibilityState={{
      expanded: visible
    }} onPress={handlePress} $_css={[smallScreenStyles.sectionAccordion, !isFirstItem && smallScreenStyles.topBorder, isLastItem && !visible && smallScreenStyles.bottomBorder]}>
          <_StyledPrText accessibilityRole="header" aria-level="2" children={label} $_css2={smallScreenStyles.sectionAccordionLabel} />
          <PrIcon accessibilityLabel="" accessibilityRole="none" fill={brandColors.primary} icon={visible ? IconName.keyboardArrowUp : IconName.keyboardArrowDown} size={iconSizes.medium} />
        </_StyledPrPressable> : <>
          <_StyledPrText2 accessibilityRole="header" aria-level="2" children={label} $_css3={largeScreenStyles.sectionHeaderLabel} />
          <_StyledView $_css4={largeScreenStyles.sectionHeaderLineAccent} />
        </>}

      {visible ? <_StyledView2 style={style} $_css5={isMediumOrSmallerScreen ? styles.paddingBottomQuadruple : undefined}>
          {children}
        </_StyledView2> : null}
    </>;
};

export default CpSection;

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpSection___StyledPrPressable",
  componentId: "sc-z7cjf4-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpSection___StyledPrText",
  componentId: "sc-z7cjf4-1"
})(["", ""], p => p.$_css2);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpSection___StyledPrText2",
  componentId: "sc-z7cjf4-2"
})(["", ""], p => p.$_css3);

var _StyledView = _styled(View).withConfig({
  displayName: "CpSection___StyledView",
  componentId: "sc-z7cjf4-3"
})(["", ""], p => p.$_css4);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpSection___StyledView2",
  componentId: "sc-z7cjf4-4"
})(["", ""], p => p.$_css5);