import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
// TODO: This can be removed once we extract fragments for tabs and type props properly

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from "react";
import { Linking, View } from "react-native";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { getNameVariant } from "services/Graphql";
import { css, styles } from "services/Theme";
import translations from "translations";
const NodeTypes = ["TopicNodeTab", "ResourceNodeTab", "ResourceNodeViewTab"];
const localStyles = {
  container: css`
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  defaultTab: css`
    align-self: flex-end;
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-style: italic;
    margin-bottom: 1px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  mutedText: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    margin-top: ${({
    theme
  }) => theme.spacing.quarter}px;
  `,
  topRow: css`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * Renders further details of an organizations tab configuration on the home page, including any specific customizations for each tab, plus links to the node in question, if applicable.
 */
const CpOrganizationConfigTabDetails = ({
  tabs
}) => {
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const handleNavigateToResourceNode = useCallback(revisionId => {
    return revisionId ? Linking.openURL(`https://admin.us.imdhealth.app/resource-trees/${revisionId}/show`) : null;
  }, []);
  const handleNavigateToTopicNode = useCallback(revisionId => {
    return revisionId ? Linking.openURL(`https://admin.us.imdhealth.app/topic-trees/${revisionId}/show`) : null;
  }, []);
  return <>
      {tabs.map(tab => {
      const isNode = NodeTypes.includes(tab.type);
      let tabName;

      if (isNode) {
        const resourceNodeName = tab?.resourceNode ? getNameVariant(tab.resourceNode) : null;
        const topicNodeName = tab?.topicNode ? getNameVariant(tab.topicNode) : null;
        tabName = resourceNodeName ?? topicNodeName ?? "";
      }

      return <_StyledView key={tab._id} $_css={localStyles.container}>
            <_StyledView2 $_css2={localStyles.topRow}>
              <_StyledView3 $_css3={styles.alignSelf}>
                <_StyledView4 $_css4={styles.row}>
                  <_StyledPrText children={tab.tabLabel} $_css5={styles.boldText} />
                  {tab.isDefault ? <_StyledPrText2 children={translations.labels.default} $_css6={localStyles.defaultTab} /> : null}
                </_StyledView4>
                {isNode ? <_StyledPrText3 children={tabName} $_css7={localStyles.mutedText} /> : null}
              </_StyledView3>
              <_StyledView5 $_css8={[styles.rowAndCenter, styles.marginLeftDouble]}>
                <View>
                  <PrText children={translations.enums.appTabTypes[tab.type]} />
                </View>
                {isNode ? <_StyledPrButton onPress={() => tab.type === "TopicNodeTab" ? handleNavigateToTopicNode(tab?.topicNode?.revisionId) : handleNavigateToResourceNode(tab?.resourceNode?.revisionId)} type="outline" $_css9={styles.marginLeftDouble}>
                    <PrIcon fill={buttonColors.primary} icon={IconName.iconTopic} size={iconSizes.medium} />
                    {
                /* <PrText
                     children="View Node"
                     css={[styles.buttonText, styles.paddingHorizontalDouble]}
                /> */
              }
                  </_StyledPrButton> : null}
              </_StyledView5>
            </_StyledView2>
            {tab?.renderWithoutHeaders ? <>
                <_StyledPrText4 children={translations.attributes.appConfiguration.renderWithoutHeaders} $_css10={styles.formStyles.readonlyLabel} />
                <_StyledPrText5 children={translations.enums.booleans[tab.renderWithoutHeaders.toString()]} $_css11={styles.formStyles.readonlyText} />
              </> : null}
            {tab?.tabParagraph ? <>
                <_StyledPrText6 children={translations.attributes.appConfiguration.tabParagraph} $_css12={styles.formStyles.readonlyLabel} />
                <_StyledPrText7 children={`"${tab?.tabParagraph}"`} $_css13={styles.formStyles.readonlyText} />
              </> : null}
            {tab?.search ? <>
                <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={translations.attributes.appConfiguration.searchMethod} value={translations.enums.appSearchTypes[tab.search.searchMethod]} />
                <CpReadonlyInput label={translations.attributes.appConfiguration.letterPicker} value={translations.enums.booleans[tab?.letterPicker?.toString() ?? "false"]} />
                <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={translations.attributes.appConfiguration.placeholder} value={tab.search?.placeholder ? `"${tab.search?.placeholder}"` : undefined} />
              </> : null}
          </_StyledView>;
    })}
    </>;
};

export default CpOrganizationConfigTabDetails;

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledView",
  componentId: "sc-1bg3frp-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledView2",
  componentId: "sc-1bg3frp-1"
})(["", ""], p => p.$_css2);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledView3",
  componentId: "sc-1bg3frp-2"
})(["", ""], p => p.$_css3);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledView4",
  componentId: "sc-1bg3frp-3"
})(["", ""], p => p.$_css4);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledPrText",
  componentId: "sc-1bg3frp-4"
})(["", ""], p => p.$_css5);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledPrText2",
  componentId: "sc-1bg3frp-5"
})(["", ""], p => p.$_css6);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledPrText3",
  componentId: "sc-1bg3frp-6"
})(["", ""], p => p.$_css7);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledView5",
  componentId: "sc-1bg3frp-7"
})(["", ""], p => p.$_css8);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledPrButton",
  componentId: "sc-1bg3frp-8"
})(["", ""], p => p.$_css9);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledPrText4",
  componentId: "sc-1bg3frp-9"
})(["", ""], p => p.$_css10);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledPrText5",
  componentId: "sc-1bg3frp-10"
})(["", ""], p => p.$_css11);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledPrText6",
  componentId: "sc-1bg3frp-11"
})(["", ""], p => p.$_css12);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigTabDetails___StyledPrText7",
  componentId: "sc-1bg3frp-12"
})(["", ""], p => p.$_css13);