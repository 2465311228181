import _styled from "styled-components";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpSignin from "components/common/CpSignin";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * The signin page for the admin app.
 */
const ScSigninAdmin = ({
  route
}) => {
  return <_StyledSafeAreaView $_css={[styles.screen, styles.flexSingle]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <CpSignin />
    </_StyledSafeAreaView>;
};

export default ScSigninAdmin;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScSigninAdmin___StyledSafeAreaView",
  componentId: "sc-1z0kyj4-0"
})(["", ""], p => p.$_css);