import _styled from "styled-components";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CtOrganizations from "components/containers/admin/CtOrganizations";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * Organizations screen for admin app
 */
const ScOrganizations = ({
  route
}) => {
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <CtOrganizations />
    </_StyledSafeAreaView>;
};

export default ScOrganizations;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScOrganizations___StyledSafeAreaView",
  componentId: "sc-13pw028-0"
})(["", ""], p => p.$_css);