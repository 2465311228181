import _styled31 from "styled-components";
import _styled30 from "styled-components";
import _styled29 from "styled-components";
import _styled28 from "styled-components";
import _styled27 from "styled-components";
import _styled26 from "styled-components";
import _styled25 from "styled-components";
import _styled24 from "styled-components";
import _styled23 from "styled-components";
import _styled22 from "styled-components";
import _styled21 from "styled-components";
import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import parsePhoneNumber from "libphonenumber-js";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Image, ScrollView, View } from "react-native";
import CpModal from "components/common/CpModal";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import CpSection from "components/common/CpSection";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { css, rnStyles, styles } from "services/Theme";
import { logoSize } from "services/Theme/common";
import translations from "translations"; // This is taken from `styles.limitedLargeScreenWidth`

const LIMITED_SCREEN_WIDTH = 720;
const localStyles = {
  childItemContainer: css`
    align-items: center;
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    flex-direction: row;
    justify-content: space-between;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  childItemSecondaryContent: css`
    align-items: flex-end;
    margin-left: ${({
    theme
  }) => theme.spacing.triple}px;
  `,
  lighterText: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
    text-transform: capitalize;
  `,
  logo: css`
    border: 1px solid ${({
    theme
  }) => theme.formColors.fieldBorderInactive};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    padding: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  logoContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `,
  noOrganization: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  sectionContentLarge: css`
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  sectionContentSmall: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  spaceBetween: css`
    justify-content: space-between;
  `
};
const localTranslations = defineMessages({
  address: {
    id: "+pHctP",
    defaultMessage: "Address"
  },
  businessHours: {
    id: "ndT2ep",
    defaultMessage: "Business hours"
  },
  businessInformation: {
    id: "CCCJ4l",
    defaultMessage: "Business information"
  },
  childrenOrganizations: {
    id: "cv1bSg",
    defaultMessage: "Child organizations ({orgCount})"
  },
  contactInformation: {
    id: "c8dtEX",
    defaultMessage: "Contact information"
  },
  licenseeNotes: {
    id: "I+YGx4",
    defaultMessage: "Licensee notes"
  },
  logos: {
    id: "lxKi2d",
    defaultMessage: "Logos"
  },
  mailerReplyToEmail: {
    id: "eGsO2C",
    defaultMessage: "Mailer Reply-to email"
  },
  moreChildren: {
    id: "fPCXNE",
    defaultMessage: "... And {remainingOrgs} more."
  },
  noChildrenOrganizations: {
    id: "291zOn",
    defaultMessage: "No child organizations found."
  },
  parentOrganization: {
    id: "vjN9xF",
    defaultMessage: "Parent organization"
  },
  phone: {
    id: "bkwOK3",
    defaultMessage: "Phone"
  },
  type: {
    id: "Tqultz",
    defaultMessage: "Type"
  },
  website: {
    id: "+jJGHD",
    defaultMessage: "Website"
  }
});

/**
 * Renders details of the organization, including contact information, address, and logos
 */
const CpOrganizationDetails = ({
  organization,
  style
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const {
    textColors
  } = useAppTheme();
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = useCallback(() => setShowModal(true), []);
  const handleHideModal = useCallback(() => setShowModal(false), []);
  const {
    navigate
  } = useNavigation();
  const handleNavigateToChildOrg = useCallback(organizationId => {
    handleHideModal(); // TODO: Ensure node is not 'possibly undefined'

    organizationId && navigate(Routes.organization, {
      id: organizationId
    });
  }, [handleHideModal, navigate]); // Extract and lay out the orgs business hours

  const organizationBusinessHours = useMemo(() => {
    const businessHours = organization?.businessHours;

    if (!Object.keys(businessHours).length) {
      return <CpReadonlyInput fallbackValue={formatMessage(translations.labels.notProvided)} label={localTranslations.businessHours} value={undefined} />;
    } // Array of days in the order we want to display them in


    const daysOfTheWeek = [["sun", formatMessage(translations.dates.sunday)], ["mon", formatMessage(translations.dates.monday)], ["tue", formatMessage(translations.dates.tuesday)], ["wed", formatMessage(translations.dates.wednesday)], ["thu", formatMessage(translations.dates.thursday)], ["fri", formatMessage(translations.dates.friday)], ["sat", formatMessage(translations.dates.saturday)]];
    return <>
        <_StyledPrText children={localTranslations.businessHours} $_css={styles.formStyles.readonlyLabel} />
        <_StyledView $_css2={[styles.rowAndCenter, styles.paddingVerticalSingle, isMediumOrSmallerScreen ? localStyles.spaceBetween : undefined]}>
          <_StyledView2 $_css3={!isMediumOrSmallerScreen ? styles.marginRightDouble : undefined}>
            {daysOfTheWeek.map(day => <PrText children={day[1]} key={day[1]} />)}
          </_StyledView2>
          <View>
            {daysOfTheWeek.map(day => {
            const [currentDay] = day;
            const hours = businessHours[currentDay] ? businessHours[currentDay] : undefined;
            let currentHours;

            if (hours) {
              const entry = Object.entries(hours)[0];
              currentHours = `${entry[0]} - ${entry[1]}`;
            } else {
              currentHours = "Closed";
            }

            return <PrText children={currentHours} />;
          })}
          </View>
        </_StyledView>
      </>;
  }, [formatMessage, isMediumOrSmallerScreen, organization?.businessHours]);
  const addressString = organization?.address.replaceAll(", ", "\n");
  const contactPhone = organization?.phoneNumber ? parsePhoneNumber(organization?.phoneNumber) : undefined;
  const children = useMemo(() => {
    return organization?.children?.edges?.map(edge => edge) ?? [];
  }, [organization?.children?.edges]);
  const childNames = useMemo(() => {
    if (children?.length) {
      let nameString = "";
      children?.slice(0, 3).map(child => nameString = `${nameString + child?.node?.name}\n`);
      return children.length > 3 ? `${nameString + formatMessage(localTranslations.moreChildren, {
        remainingOrgs: organization?.childCount ? organization.childCount - 3 : children.length - 3
      })}` : nameString;
    } else {
      return localTranslations.noChildrenOrganizations;
    }
  }, [children, formatMessage, organization]); // Organize and lay out organization logos

  const organizationLogos = useMemo(() => {
    const logos = organization?.iconTranslations;
    const systemLocales = Object.keys(translations.enums.locales); // We're limiting the screen width so we'll work with that for calculating logo sizes
    // This will likely break to some extent should we support additional languages

    const orgLogoWidth = isMediumOrSmallerScreen ? logoSize * 1.5 : // 32 === spacing between 3 logos, and using 3 because that's the most we'll render in a single row
    (LIMITED_SCREEN_WIDTH - 32) / 3;
    const orgLogoHeight = isMediumOrSmallerScreen ? logoSize : orgLogoWidth * 0.5;
    const logoContainerStyle = css`
      height: ${orgLogoHeight}px;
      width: ${orgLogoWidth}px;
      ${localStyles.logo}
    `;
    return <_StyledCpSection label={localTranslations.logos} $_css4={[styles.paddingBottomQuadruple, isMediumOrSmallerScreen ? localStyles.sectionContentSmall : localStyles.sectionContentLarge]}>
        {systemLocales.map((locale, index) => {
        const logoSource = logos?.[locale];
        const padFirstLogo = isMediumOrSmallerScreen && index === 0;
        const separateVertically = isMediumOrSmallerScreen && index > 0;
        const separateHorizontally = !isMediumOrSmallerScreen && index > 0;
        return <_StyledView3 key={locale} $_css5={[isMediumOrSmallerScreen ? localStyles.logoContainer : styles.marginTopSingle, padFirstLogo ? styles.paddingTopSingle : undefined, separateVertically ? styles.marginTopSingle : undefined, separateHorizontally ? styles.marginLeftDouble : undefined]}>
              <_StyledPrText2 children={translations.enums.locales[locale]} $_css6={[styles.marginBottomSingle, !isMediumOrSmallerScreen ? styles.marginTopSingle : undefined]} />
              {logoSource ? <_StyledImage resizeMode="contain" source={logoSource} $_css7={logoContainerStyle} /> : <_StyledView4 $_css8={[logoContainerStyle, styles.center]}>
                  <PrIcon fill={textColors.disabled} icon={IconName.close} size={orgLogoHeight - 24} />
                </_StyledView4>}
            </_StyledView3>;
      })}
      </_StyledCpSection>;
  }, [isMediumOrSmallerScreen, organization?.iconTranslations, textColors.disabled]);

  if (!organization) {
    return <_StyledView5 $_css9={styles.center}>
        <_StyledPrText3 $_css10={localStyles.noOrganization}>
          <FormattedMessage id="WrSr8j" defaultMessage="This organization does not have any details to display. Please refresh and try again. If the issue persists, please contact IMD Health Support." />
        </_StyledPrText3>
      </_StyledView5>;
  }

  return <View style={style}>
      <_StyledView6 $_css11={[styles.rowAndCenter, styles.marginTopDouble, isMediumOrSmallerScreen ? localStyles.spaceBetween : undefined, isMediumOrSmallerScreen ? styles.paddingDouble : undefined]}>
        <_StyledPrText4 children={translations.enums.status[organization.status]} $_css12={styles.marginRightDouble} />
        <PrText children={organization.countryName} />
      </_StyledView6>

      {
      /* General Organization & Child Section */
    }
      <_StyledView7 $_css13={isMediumOrSmallerScreen ? styles.paddingDouble : undefined}>
        <_StyledCpReadonlyInput fallbackValue={translations.labels.notProvided} label={localTranslations.type} value={translations.enums.organizationTypes[organization.type]} $_css14={!isMediumOrSmallerScreen ? styles.marginTopSingle : undefined} />
        <CpReadonlyInput // TODO: How do we want to handle IMD Health Global?
      label={localTranslations.parentOrganization} value={organization.parent?.name} />
        <CpReadonlyInput label={formatMessage(localTranslations.childrenOrganizations, {
        orgCount: organization?.childCount
      })} value={childNames} />
        <_StyledPrButton disabled={!children?.length} onPress={handleShowModal} type="outline" $_css15={styles.marginBottomDouble}>
          <_StyledPrText5 children={translations.buttons.view} $_css16={styles.buttonText} />
        </_StyledPrButton>
      </_StyledView7>

      {
      /* Address Section */
    }
      <_StyledCpSection2 label={localTranslations.address} visibleByDefault $_css17={isMediumOrSmallerScreen ? localStyles.sectionContentSmall : undefined}>
        <_StyledCpReadonlyInput2 label={localTranslations.address} value={addressString} $_css18={!isMediumOrSmallerScreen ? styles.marginTopSingle : undefined} />
      </_StyledCpSection2>

      {
      /* Contact Information Section */
    }
      <_StyledCpSection3 label={localTranslations.contactInformation} $_css19={isMediumOrSmallerScreen ? localStyles.sectionContentSmall : undefined}>
        <_StyledCpReadonlyInput3 fallbackValue={translations.labels.notProvided} label={localTranslations.phone} value={contactPhone?.formatNational()} $_css20={!isMediumOrSmallerScreen ? styles.marginTopSingle : undefined} />
        <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={translations.attributes.user.email} value={organization.primaryContactEmail} />
        <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={localTranslations.website} value={organization.website} />
        <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={localTranslations.mailerReplyToEmail} value={organization.mailerReplyTo} />
      </_StyledCpSection3>

      {
      /* Business Information Section */
    }
      <_StyledCpSection4 label={localTranslations.businessInformation} $_css21={isMediumOrSmallerScreen ? localStyles.sectionContentSmall : undefined}>
        <_StyledCpReadonlyInput4 fallbackValue={translations.labels.notProvided} label={localTranslations.licenseeNotes} value={organization.licenseeNote} $_css22={!isMediumOrSmallerScreen ? styles.marginTopSingle : undefined} />
        {organizationBusinessHours}
      </_StyledCpSection4>

      {
      /* Logo Section */
    }
      {organizationLogos}

      {
      /* Children Organization Modal */
    }
      <CpModal onBackgroundPress={handleHideModal} visible={showModal}>
        <_StyledPrText6 children={formatMessage(localTranslations.childrenOrganizations, {
        orgCount: children?.length ?? 0
      })} $_css23={styles.modalHeaderText} />
        <_StyledScrollView contentContainerStyle={rnStyles.flexSingle} $_css24={styles.scrollModal}>
          {children?.map((child, index) => {
          const node = child?.node;
          const {
            _id,
            childCount,
            name,
            status,
            type
          } = node ?? {};
          const hasType = type && Object.keys(translations.enums.organizationTypes).includes(type);
          return <_StyledPrPressable // eslint-disable-next-line react/no-array-index-key
          key={(child?.node?.name ?? "childOrg") + index} onPress={() => handleNavigateToChildOrg(_id)} $_css25={localStyles.childItemContainer}>
                <_StyledView8 $_css26={styles.flexSingle}>
                  <PrText children={name} />
                  <_StyledPrText7 children={formatMessage(translations.labels.children, {
                num: childCount
              })} $_css27={localStyles.lighterText} />
                </_StyledView8>
                <_StyledView9 $_css28={localStyles.childItemSecondaryContent}>
                  <_StyledPrText8 children={status} $_css29={localStyles.lighterText} />
                  <_StyledPrText9 children={hasType ? translations.enums.organizationTypes[type] : translations.labels.unknown} $_css30={localStyles.lighterText} />
                </_StyledView9>
              </_StyledPrPressable>;
        })}
        </_StyledScrollView>
        <PrButton onPress={handleHideModal} type="text">
          <_StyledPrText10 children={translations.buttons.close} $_css31={styles.buttonText} />
        </PrButton>
      </CpModal>
    </View>;
};

export default CpOrganizationDetails;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText",
  componentId: "sc-3fxagt-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationDetails___StyledView",
  componentId: "sc-3fxagt-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpOrganizationDetails___StyledView2",
  componentId: "sc-3fxagt-2"
})(["", ""], p => p.$_css3);

var _StyledCpSection = _styled(CpSection).withConfig({
  displayName: "CpOrganizationDetails___StyledCpSection",
  componentId: "sc-3fxagt-3"
})(["", ""], p => p.$_css4);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpOrganizationDetails___StyledView3",
  componentId: "sc-3fxagt-4"
})(["", ""], p => p.$_css5);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText2",
  componentId: "sc-3fxagt-5"
})(["", ""], p => p.$_css6);

var _StyledImage = _styled(Image).withConfig({
  displayName: "CpOrganizationDetails___StyledImage",
  componentId: "sc-3fxagt-6"
})(["", ""], p => p.$_css7);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpOrganizationDetails___StyledView4",
  componentId: "sc-3fxagt-7"
})(["", ""], p => p.$_css8);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpOrganizationDetails___StyledView5",
  componentId: "sc-3fxagt-8"
})(["", ""], p => p.$_css9);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText3",
  componentId: "sc-3fxagt-9"
})(["", ""], p => p.$_css10);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpOrganizationDetails___StyledView6",
  componentId: "sc-3fxagt-10"
})(["", ""], p => p.$_css11);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText4",
  componentId: "sc-3fxagt-11"
})(["", ""], p => p.$_css12);

var _StyledView7 = _styled(View).withConfig({
  displayName: "CpOrganizationDetails___StyledView7",
  componentId: "sc-3fxagt-12"
})(["", ""], p => p.$_css13);

var _StyledCpReadonlyInput = _styled(CpReadonlyInput).withConfig({
  displayName: "CpOrganizationDetails___StyledCpReadonlyInput",
  componentId: "sc-3fxagt-13"
})(["", ""], p => p.$_css14);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpOrganizationDetails___StyledPrButton",
  componentId: "sc-3fxagt-14"
})(["", ""], p => p.$_css15);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText5",
  componentId: "sc-3fxagt-15"
})(["", ""], p => p.$_css16);

var _StyledCpSection2 = _styled(CpSection).withConfig({
  displayName: "CpOrganizationDetails___StyledCpSection2",
  componentId: "sc-3fxagt-16"
})(["", ""], p => p.$_css17);

var _StyledCpReadonlyInput2 = _styled(CpReadonlyInput).withConfig({
  displayName: "CpOrganizationDetails___StyledCpReadonlyInput2",
  componentId: "sc-3fxagt-17"
})(["", ""], p => p.$_css18);

var _StyledCpSection3 = _styled(CpSection).withConfig({
  displayName: "CpOrganizationDetails___StyledCpSection3",
  componentId: "sc-3fxagt-18"
})(["", ""], p => p.$_css19);

var _StyledCpReadonlyInput3 = _styled(CpReadonlyInput).withConfig({
  displayName: "CpOrganizationDetails___StyledCpReadonlyInput3",
  componentId: "sc-3fxagt-19"
})(["", ""], p => p.$_css20);

var _StyledCpSection4 = _styled(CpSection).withConfig({
  displayName: "CpOrganizationDetails___StyledCpSection4",
  componentId: "sc-3fxagt-20"
})(["", ""], p => p.$_css21);

var _StyledCpReadonlyInput4 = _styled(CpReadonlyInput).withConfig({
  displayName: "CpOrganizationDetails___StyledCpReadonlyInput4",
  componentId: "sc-3fxagt-21"
})(["", ""], p => p.$_css22);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText6",
  componentId: "sc-3fxagt-22"
})(["", ""], p => p.$_css23);

var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpOrganizationDetails___StyledScrollView",
  componentId: "sc-3fxagt-23"
})(["", ""], p => p.$_css24);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpOrganizationDetails___StyledPrPressable",
  componentId: "sc-3fxagt-24"
})(["", ""], p => p.$_css25);

var _StyledView8 = _styled(View).withConfig({
  displayName: "CpOrganizationDetails___StyledView8",
  componentId: "sc-3fxagt-25"
})(["", ""], p => p.$_css26);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText7",
  componentId: "sc-3fxagt-26"
})(["", ""], p => p.$_css27);

var _StyledView9 = _styled(View).withConfig({
  displayName: "CpOrganizationDetails___StyledView9",
  componentId: "sc-3fxagt-27"
})(["", ""], p => p.$_css28);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText8",
  componentId: "sc-3fxagt-28"
})(["", ""], p => p.$_css29);

var _StyledPrText9 = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText9",
  componentId: "sc-3fxagt-29"
})(["", ""], p => p.$_css30);

var _StyledPrText10 = _styled(PrText).withConfig({
  displayName: "CpOrganizationDetails___StyledPrText10",
  componentId: "sc-3fxagt-30"
})(["", ""], p => p.$_css31);