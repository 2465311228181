import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useMemo } from "react";
import { View } from "react-native";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import useScreenLayout from "hooks/useScreenLayout";
import { css, styles } from "services/Theme";
import translations from "translations";
import PrText from "components/primitives/PrText";
const localStyles = {
  hideRule: css`
    ${styles.formStyles.readonlyText};
    padding: 0px;
  `
};

/**
 * Renders the details of an organizations configuration regarding the application footer.
 */
const CpOrganizationConfigFooter = ({
  organization
}) => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const appConfig = organization?.appConfig;
  const hideRulesFooter = useMemo(() => {
    const contentToReturn = [];

    if (appConfig?.appFooter?.hideRules?.length) {
      appConfig.appFooter.hideRules.map((rule, ruleIndex) => {
        let routes = "all routes";

        if (rule.routes.length) {
          routes = "";
          rule.routes.map((route, index) => {
            const ruleLength = rule.routes.length;
            const append = ruleLength > 1 && ruleLength !== index ? ", " : "";
            return routes += route + append;
          });
        }

        contentToReturn.push(<_StyledPrText children={`${ruleIndex + 1} - ${rule.hide ? "Hide" : "Show"} on ${routes}`} // TODO: Do better index
        // eslint-disable-next-line react/no-array-index-key
        key={`rule-${ruleIndex}`} $_css={localStyles.hideRule} />);
      });
    } else {
      // TODO: Would we ever have empty hide_rules?  Need to handle it better
      contentToReturn.push(<PrText children="TODO" />);
    }

    return <>
        <_StyledPrText2 children="Hide Rules" $_css2={[styles.formStyles.readonlyLabel, styles.marginVerticalSingle]} />
        <_StyledView $_css3={styles.marginBottomDouble}>{contentToReturn}</_StyledView>
      </>;
  }, [appConfig?.appFooter?.hideRules]);
  return <_StyledView2 $_css4={isMediumOrSmallerScreen ? styles.paddingDouble : undefined}>
      <CpReadonlyInput fallbackValue={translations.labels.notProvided} label={translations.attributes.appConfiguration.disclaimer} value={appConfig?.appFooter?.disclaimer ? `"${appConfig.appFooter.disclaimer}"` : undefined} />
      {hideRulesFooter}
    </_StyledView2>;
};

export default CpOrganizationConfigFooter;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigFooter___StyledPrText",
  componentId: "sc-18un8c0-0"
})(["", ""], p => p.$_css);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpOrganizationConfigFooter___StyledPrText2",
  componentId: "sc-18un8c0-1"
})(["", ""], p => p.$_css2);

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationConfigFooter___StyledView",
  componentId: "sc-18un8c0-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpOrganizationConfigFooter___StyledView2",
  componentId: "sc-18un8c0-3"
})(["", ""], p => p.$_css4);