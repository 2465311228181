import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { View } from "react-native";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import { styles } from "services/Theme";
import translations from "translations";
import PrRadioSegmented from "components/primitives/PrRadioSegmented";
import { DisplayDirection } from "components/form/types";
import CpOrganizationConfigTools from "components/organization/admin/organizations/CpOrganizationConfigTools";
import CpOrganizationConfigAds from "components/organization/admin/organizations/CpOrganizationConfigAds";
import CpOrganizationConfigFooter from "components/organization/admin/organizations/CpOrganizationConfigFooter";
import CpOrganizationConfigHeader from "components/organization/admin/organizations/CpOrganizationConfigHeader";
import CpOrganizationConfigHome from "components/organization/admin/organizations/CpOrganizationConfigHome";
import CpOrganizationConfigStyles from "components/organization/admin/organizations/CpOrganizationConfigStyles";
import CpOrganizationConfigDefault from "components/organization/admin/organizations/CpOrganizationConfigDefault";
var ConfigSections;

(function (ConfigSections) {
  ConfigSections["Config"] = "Config";
  ConfigSections["Tools"] = "Tools";
  ConfigSections["Ads"] = "Ads";
  ConfigSections["Header"] = "Header";
  ConfigSections["Home"] = "Home";
  ConfigSections["Footer"] = "Footer";
  ConfigSections["Styles"] = "Styles";
})(ConfigSections || (ConfigSections = {}));

/**
 * Starting point for seeing details of an organizations application configuration. Renders CpOrganizationConfig___ components.
 */
const CpOrganizationConfig = ({
  organization,
  style
}) => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const appConfig = organization?.appConfig;
  const sectionOptions = useMemo(() => {
    return [{
      name: formatMessage(translations.attributes.appConfiguration.configuration),
      value: ConfigSections.Config
    }, {
      name: formatMessage(translations.attributes.appConfiguration.tools),
      value: ConfigSections.Tools
    }, {
      name: formatMessage(translations.attributes.appConfiguration.advertisements),
      value: ConfigSections.Ads
    }, {
      name: formatMessage(translations.attributes.appConfiguration.header),
      value: ConfigSections.Header
    }, {
      name: formatMessage(translations.attributes.appConfiguration.homeConfig),
      value: ConfigSections.Home
    }, {
      name: formatMessage(translations.attributes.appConfiguration.footer),
      value: ConfigSections.Footer
    }, {
      name: formatMessage(translations.attributes.appConfiguration.styles),
      value: ConfigSections.Styles
    }];
  }, [formatMessage]);
  const [currentSection, setCurrentSection] = useState(ConfigSections.Config);
  const handleSetSection = useCallback(newSection => setCurrentSection(ConfigSections[newSection]), []);
  const CurrentSectionComponent = useMemo(() => {
    switch (currentSection) {
      case ConfigSections.Tools:
        return <CpOrganizationConfigTools organization={organization} />;

      case ConfigSections.Ads:
        return <CpOrganizationConfigAds organization={organization} />;

      case ConfigSections.Header:
        return <CpOrganizationConfigHeader organization={organization} />;

      case ConfigSections.Home:
        return <CpOrganizationConfigHome organization={organization} />;

      case ConfigSections.Footer:
        return <CpOrganizationConfigFooter organization={organization} />;

      case ConfigSections.Styles:
        return <CpOrganizationConfigStyles organization={organization} />;

      case ConfigSections.Config:
      default:
        return <CpOrganizationConfigDefault organization={organization} />;
    }
  }, [currentSection, organization]);

  if (!organization) {
    return <View style={style}>
        <PrText>
          <FormattedMessage id="WrSr8j" defaultMessage="This organization does not have any details to display. Please refresh and try again. If the issue persists, please contact IMD Health Support." />
        </PrText>
      </View>;
  } else if (!appConfig) {
    return <View style={style}>
        <PrText>
          <FormattedMessage id="ztR9SR" defaultMessage="This organization does not have an Application Configuration set up. They will inherit any properties from the nearest parent organization with an established configuration." />
        </PrText>
      </View>;
  }

  return <_StyledView style={style} $_css={styles.marginBottomDouble}>
      <_StyledPrRadioSegmented displayDirection={DisplayDirection.Horizontal} onChangeOption={handleSetSection} radioOptions={sectionOptions} value={currentSection} $_css2={[isMediumOrSmallerScreen ? styles.marginHorizontalDouble : undefined, styles.marginBottomDouble]} />

      {CurrentSectionComponent}
    </_StyledView>;
};

export default CpOrganizationConfig;

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationConfig___StyledView",
  componentId: "sc-c9s4ko-0"
})(["", ""], p => p.$_css);

var _StyledPrRadioSegmented = _styled(PrRadioSegmented).withConfig({
  displayName: "CpOrganizationConfig___StyledPrRadioSegmented",
  componentId: "sc-c9s4ko-1"
})(["", ""], p => p.$_css2);